import { Box, SimpleGrid, Tab, TabList, Tabs, Text, VStack } from '@chakra-ui/react';
import { MAWorkFlowHeader } from '../MAWorkFlowHeader';
import { useState } from 'react';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { useSubmitMedication } from './hooks/useSubmitMedication';
import { useMedicationState } from './hooks/useMedicationState';
import { isEmpty } from 'lodash';
import { MedicationsContent } from './MedicationsContent';
import { PastRX } from './PastRx';
import { Flex } from '../../../components';
import { ExternalRX } from './ExternalRx';

interface Props {
  nextStep: () => void;
}

const tabs = ['Current Medications', 'Past RX', 'External RX'];
type MyTab = (typeof tabs)[number];

export function MedicationsContainer({ nextStep }: Props) {
  const [activeTab, setActiveTab] = useState<MyTab>('Current Medications');
  const statuses = ['Taking', 'Not Taking', 'Unknown', 'Discontinued'];
  const { handleSubmitUpdateMedication, loading } = useSubmitMedication({ nextStep });
  const medicationState = useMedicationState();

  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='md' alignItems='flex-start'>
        <SimpleGrid columns={3} alignItems='baseline' w='full' justifyContent='space-between'>
          <MAWorkFlowHeader>Medications</MAWorkFlowHeader>
          <Tabs
            colorScheme='brand'
            defaultIndex={0}
            onChange={(i) => {
              setActiveTab(tabs[i]);
            }}>
            <Box>
              <TabList>
                {tabs.map((tab) => (
                  <Tab whiteSpace='nowrap' w='50%' pb={1} pt={4} key={tab}>
                    {tab}
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Tabs>
          <Flex justifyContent='end' gap='sm'>
            {statuses.map((status) => (
              <StatusText textValue={status} key={status} />
            ))}
          </Flex>
        </SimpleGrid>
        {activeTab === 'Current Medications' && <MedicationsContent />}
        {activeTab === 'Past RX' && <PastRX />}
        {activeTab === 'External RX' && <ExternalRX />}
      </VStack>
      <SaveAndWorklistButtons
        onClick={handleSubmitUpdateMedication}
        isLoading={loading}
        disabled={isEmpty(medicationState.addedMedications)}>
        Save / Verify Medications
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}

function StatusText({ textValue }: { textValue: string }) {
  const firstLetter = `${textValue.substring(0, 1)}`;
  const otherLetters = `${textValue.substring(1)}`;
  return (
    <Box display='inline'>
      <Text display='inline' color='secondary' fontWeight='bold'>
        {firstLetter}
      </Text>
      <Text display='inline' color='secondary'>
        {otherLetters}
      </Text>
    </Box>
  );
}

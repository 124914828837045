import {
  Box,
  Button,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AddOrEditImmunizationHistoryButton } from './AddOrEditImmunizationHistoryButton';
import { PastImmunizations } from './PastImmunizations';
import { Scrollable } from '../../../../components';
import { UserInfoMini } from '../../../../components/data-display/UserInfo/UserInfo/UserInfoMini';
import { PublicHealth } from './PublicHealth';
import { useState } from 'react';
import { LastRetrievedAndRetrieveButton } from './LastRetrievedAndRetrieveButton';

export function ImmunizationHistoryDrawer({ onClose }: { onClose: () => void }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <Box height='100%' width='100%'>
        <Flex>
          <Box
            minHeight='100vh'
            width='20%'
            backgroundColor='#ED4924'
            sx={{ '@media print': { display: 'none' } }}
          />
          <Box width='80%' marginTop='50px' sx={{ '@media print': { width: '100%' } }}>
            <Flex justifyContent='center'>
              <VStack width='95%' spacing={'lg'} align={'stretch'}>
                <UserInfoMini />
                <Text variant='h6-b'>Immunizations</Text>

                <Tabs
                  colorScheme='brand'
                  index={activeIndex}
                  onChange={(index) => {
                    setActiveIndex(index);
                  }}>
                  <Flex>
                    <TabList w='fit-content'>
                      <Tab>Immunizations</Tab>
                      <Tab>Public Health</Tab>
                    </TabList>
                    <Spacer />
                    {activeIndex === 0 ? (
                      <AddOrEditImmunizationHistoryButton />
                    ) : (
                      <LastRetrievedAndRetrieveButton />
                    )}
                  </Flex>
                  <TabPanels>
                    <TabPanel>
                      <Scrollable maxH='46vh'>
                        <PastImmunizations />
                      </Scrollable>
                    </TabPanel>
                    <TabPanel>
                      <Scrollable maxH='46vh'>
                        <PublicHealth />
                      </Scrollable>
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                <Button colorScheme='blue' onClick={onClose}>
                  Back
                </Button>
              </VStack>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </DrawerContent>
  );
}

import { Text, TableContainer, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import { useGetImmunizationInjectionPatientQuery } from '../../../../__generated__/graphql';
import { useActiveEncounter, usePatient } from '../../../../hooks';
import { isEmpty } from 'lodash';
import { WaitUntilLoaded } from '../../../../components';
import { RowWithDisabledInjectionFormModal } from './RowWithDisabledInjectionFormModal';

export function PastImmunizations() {
  const { patientId } = usePatient();
  const { activeEncounterId } = useActiveEncounter();
  const { data, loading } = useGetImmunizationInjectionPatientQuery({
    variables: {
      patientId,
    },
  });

  const vaccines = data?.getImmunizationInjectionPatient
    ?.filter((item) => {
      // remove empty rows from showing in page
      if (!item.immunizationName) {
        return false;
      }

      // remove current encounter from showing in page if vaccinationGivenInThePast is false
      if (item.sourceEncounterId === activeEncounterId && !item.vaccinationGivenInThePast) {
        return false;
      }

      return true;
    })
    ?.sort((a, b) => {
      if (b.immunizationId && a.immunizationId) {
        return b.immunizationId - a.immunizationId;
      }
      return 0;
    });

  return (
    <WaitUntilLoaded loading={loading}>
      {isEmpty(vaccines) ? (
        <Text>No data</Text>
      ) : (
        <>
          <TableContainer>
            <Table size='md' variant='simple'>
              <Thead>
                <Tr>
                  <Th color='secondary'>Name</Th>
                  <Th color='secondary'>Dose</Th>
                  <Th color='secondary'>Date</Th>
                  <Th color='secondary'>Location</Th>
                  <Th color='secondary'>Lot #</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {vaccines?.map((v) => {
                  return <RowWithDisabledInjectionFormModal key={v.immunizationId} injection={v} />;
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </WaitUntilLoaded>
  );
}

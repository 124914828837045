import { useMemo, useState } from 'react';
import { type HpiSmartFormEntity, type HpiStructuredData } from '../../../../__generated__/graphql';
import { Phq9ScoreCalc } from './calculators/phq9Score';
import { Phq9ScoreInterpretation } from './calculators/phq9ScoreInterpretation';
import { Phq2ScoreCalc } from './calculators/phq2Score';

interface Props {
  smartForms: HpiSmartFormEntity[];
  responses: HpiStructuredData[];
  changeHandler: (response: Partial<HpiStructuredData>) => void;
}

export const useScoring = ({ smartForms, changeHandler }: Props) => {
  const [nextPage, setNextPage] = useState<{ symptomId: number; categoryId: number } | undefined>(
    undefined,
  );

  // get the calculators that apply to the provided smartForms
  const appliedCalculators = useMemo(() => {
    const calcs = [
      new Phq9ScoreCalc(smartForms),
      new Phq9ScoreInterpretation(smartForms),
      new Phq2ScoreCalc(smartForms),
    ];
    const applied = calcs.filter((c) => c.isActive());
    return applied;
  }, [smartForms]);

  const getScore = (responses: HpiStructuredData[]) => {
    // destructure the responses so we can mutate them
    // or else we'll get a react warning about read-only values
    let updatedResponses = [...responses];

    appliedCalculators.forEach((calc) => {
      const { hasUpdate, score } = calc.getValue(responses);
      const nextPage = calc.nextPageHandler();
      setNextPage(nextPage);

      if (hasUpdate) {
        const existingIndex = updatedResponses.findIndex(
          (r) => r.hpiStructuredDataDetailId === calc.computedField,
        );

        if (existingIndex >= 0) {
          updatedResponses = updatedResponses.map((r) => {
            if (r.hpiStructuredDataDetailId === calc.computedField) {
              return {
                ...r,
                value: `${score}`,
              };
            }
            return r;
          });
        } else {
          updatedResponses.push({
            hpiStructuredDataDetailId: calc.computedField,
            value: `${score}`,
          });
        }
      }
    });

    return updatedResponses;
  };

  return {
    // computed fields should not be manually updated
    disabledFields: appliedCalculators.map((c) => c.computedField),
    nextPage,
    getScore,
  };
};

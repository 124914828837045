import {
  Button,
  Flex,
  Input,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  VStack,
  SimpleGrid,
  Icon,
} from '@chakra-ui/react';
import { IoIosRefresh } from 'react-icons/io';
import {
  useGetExternalMedsByPatientQuery,
  useRetrieveExternalMedsMutation,
} from '../../../../__generated__/graphql';
import { TakingButton } from '../PastRx/TakingButton';
import { useState } from 'react';
import { usePatient } from '../../../../hooks';
import { useMedicationState } from '../hooks/useMedicationState';
import { useUpdateMedItems } from '../hooks/useUpdateMedItems';
import { MedStatusValues } from '../../../../types';
import { v4 } from 'uuid';

export function ExternalRX() {
  const patient = usePatient();
  // THE AYLO DEV ENVIRONMENT IS HARDCODED TO ONLY ACCEPT A FEW PATIENT IDS SINCE
  // IT NEEDS TO HIT AN EXTERNAL PROVIDER.
  const isDev = window.location.hostname.includes('dev') || process.env.NODE_ENV === 'development';
  const patientId = isDev ? 264084 : patient.patientId;
  const [retrieve, { loading: retrieveLoading, called }] = useRetrieveExternalMedsMutation();
  const {
    data,
    refetch,
    loading: medsLoading,
  } = useGetExternalMedsByPatientQuery({
    variables: {
      sourcePatientId: patientId,
    },
  });

  const meds = data?.getExternalMedsByPatient ?? [];
  const loading = retrieveLoading || medsLoading;
  const [searchTerm, setSearchTerm] = useState('');

  const { addedMedications } = useMedicationState();
  const { handleRemoveMedications, handleUpsertMeds } = useUpdateMedItems();

  const notRetrievedYet = !meds?.length && !loading && !called;
  const noExternalMeds = !meds?.length && !loading && !!called;

  const lastRetrievedDate = meds.length
    ? new Date(Math.max(...meds.map((med) => new Date(med.created).getTime())))
    : null;

  const filteredMeds = meds?.filter((med) =>
    med?.drugName?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <VStack gap='lg' w='full'>
      <Flex gap='lg' w='full' alignItems='center'>
        <Input
          placeholder='Find RX'
          w='full'
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <Text color='brand.500' whiteSpace='nowrap' fontSize='sm'>
          Last Retrieved: {lastRetrievedDate?.toLocaleString('en-US') || 'Never'}
        </Text>
        <Button
          isLoading={loading}
          onClick={() => {
            retrieve({
              variables: {
                sourcePatientId: patientId,
              },
            }).then(() => {
              refetch();
            });
          }}
          px={12}
          rightIcon={<Icon as={IoIosRefresh} />}>
          Retrieve
        </Button>
      </Flex>

      {notRetrievedYet && <Text>No external medications retrieved yet</Text>}
      {noExternalMeds && <Text>No external medications for patient</Text>}

      {!loading && (
        <Accordion w='full' allowMultiple>
          {filteredMeds?.map((med, index) => {
            const addedMed = addedMedications.find((m) => m.medicationItem === med.drugName);
            const isActive = addedMed?.newStatus === MedStatusValues.T.toString();

            return (
              <AccordionItem key={index} mb={4} border='none' borderRadius='md' boxShadow='md'>
                <AccordionButton borderRadius='md' p={4}>
                  <AccordionIcon mr={4} />
                  <Box flex='1'>
                    <Flex justifyContent='space-between' alignItems='center'>
                      <Flex gap={4}>
                        <Text fontWeight='bold'>
                          {med.drugName} {med.strength}
                        </Text>
                        <Text>Fills: {med.refills}</Text>
                      </Flex>
                      <Flex gap={4} alignItems='center'>
                        <Text fontWeight={'bold'} whiteSpace='nowrap' fontSize='sm'>
                          Last Filled: {new Date(med.lastFillDate).toLocaleDateString()}
                        </Text>
                        <TakingButton
                          active={isActive}
                          onClick={() => {
                            if (isActive) {
                              handleRemoveMedications([addedMed.id]);
                            } else {
                              const uiMed: MedicationItemUI = {
                                id: v4(),
                                medicationItem: med.drugName,
                                medicationStrength: med.strength,
                                medicationFormulation: med.formulation,
                                medicationTake: med.direction,
                                medicationDuration: med.daysSupply,
                                medicationRefills: med.refills,
                                medicationStartDate: med.lastFillDate,
                                medicationStopDate: null,
                                rxSource: med.providerName,
                                status: MedStatusValues.T.toString(),
                                newStatus: MedStatusValues.T.toString(),
                              };

                              handleUpsertMeds([uiMed]);
                            }
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </AccordionButton>
                <AccordionPanel pb={4} px={4}>
                  <SimpleGrid border={'1px solid'} borderColor='gray.200' columns={3}>
                    <VStack p={3} border={'1px solid'} borderColor='gray.200'>
                      <Text fontSize={'sm'} w='full'>
                        Direction: {med.direction ?? 'N/A'}
                      </Text>
                      <SimpleGrid w='full' columns={2} spacing={4}>
                        <Text w='full' fontSize='sm'>
                          Qty: {med.quantity ?? 'N/A'}
                        </Text>
                        <Text w='full' fontSize='sm'>
                          Duration: {med.daysSupply ?? 'N/A'}
                        </Text>
                        <Text w='full' fontSize='sm'>
                          Written Date:{' '}
                          {med.writtenDate ? new Date(med.writtenDate).toLocaleDateString() : 'N/A'}
                        </Text>
                        <Text w='full' fontSize='sm'>
                          Fill Date:{' '}
                          {med.lastFillDate
                            ? new Date(med.lastFillDate).toLocaleDateString()
                            : 'N/A'}
                        </Text>
                      </SimpleGrid>
                    </VStack>
                    <VStack p={3} border={'1px solid'} borderColor='gray.200'>
                      <Text w='full' fontSize='sm'>
                        Primary Diagnosis: {med.primaryDiagnosis ?? 'N/A'}
                      </Text>
                      <Text w='full' fontSize='sm'>
                        Secondary Diagnosis: {med.secondaryDiagnosis ?? 'N/A'}
                      </Text>
                      <Text w='full' fontSize='sm'>
                        Refills Remaining: {med.refillRemaining ?? 'N/A'}
                      </Text>
                    </VStack>
                    <VStack p={3} border={'1px solid'} borderColor='gray.200'>
                      <Text w='full' fontSize='sm'>
                        Prescriber: {med.providerName ?? 'N/A'}
                      </Text>
                      <Text w='full' fontSize='sm'>
                        Dispensing Pharmacy: {med.pharmacy ?? 'N/A'}
                      </Text>
                    </VStack>
                  </SimpleGrid>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </VStack>
  );
}

import { Box, WaitUntilLoaded } from '../../layout';
import {
  resourceActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { type ComboBoxOption } from '../../../types';
import { BiChevronDown, BiX } from 'react-icons/bi';
import { Icon } from '../../general';
import {
  type Resource,
  useGetProvidersByLocationQuery,
  useGetResourcesQuery,
} from '../../../__generated__/graphql';
import { ComboBox } from '../../form';
import { useMemo, useState } from 'react';
import {
  chakraComponents,
  type ClearIndicatorProps,
  type DropdownIndicatorProps,
  type GroupBase,
} from 'chakra-react-select';
import { type ChakraStylesConfig } from 'chakra-react-select/dist/types/types';

function sortDesc(a: ComboBoxOption, b: ComboBoxOption) {
  const aVal = a?.label;
  const bVal = b?.label;

  if (aVal > bVal) {
    return 1;
  }
  if (aVal < bVal) {
    return -1;
  }
  return 0;
}

export function ProviderResourceSelector() {
  const locationId = useAppSelector(selectors.getSelectedLocationID);

  const {
    data: resourcesData,
    loading: resourcesIsLoading,
    error: resourcesError,
  } = useGetResourcesQuery();
  const {
    data: providersData,
    loading: providersIsLoading,
    error: providersError,
  } = useGetProvidersByLocationQuery({ variables: { locationId } });

  // Convert resources to Combobox options
  const resourceOptions =
    resourcesData?.getResources
      ?.filter((r: Resource) => r.locationId === locationId)
      .map((r: Resource) => {
        return {
          label: r.name || '',
          value: r.id.toString() || '',
        };
      }) || [];

  // Convert providers to Combobox options
  const providerOptions =
    providersData?.getProvidersByLocation?.map((p) => {
      return {
        label: p ? `${p.lastName || ''}, ${p.firstName || ''}` : '',
        value: p.providerID.toString() || '',
      };
    }) || [];

  // Combine resources and providers
  const options: ComboBoxOption[] = useMemo(() => {
    return resourceOptions && providerOptions
      ? resourceOptions.concat(providerOptions).sort(sortDesc)
      : [];
  }, [resourceOptions, providerOptions]);

  const [search, setSearch] = useState('');
  const components = {
    DropdownIndicator: (
      props: DropdownIndicatorProps<ComboBoxOption, boolean, GroupBase<ComboBoxOption>>,
    ) => {
      return (
        <chakraComponents.DropdownIndicator {...props}>
          <Icon boxSize={10} color='brand.400' as={BiChevronDown} />
        </chakraComponents.DropdownIndicator>
      );
    },
    ClearIndicator: (
      props: ClearIndicatorProps<ComboBoxOption, boolean, GroupBase<ComboBoxOption>>,
    ) => (
      <chakraComponents.ClearIndicator {...props}>
        <Icon boxSize={9} color='brand.400' as={BiX} />
      </chakraComponents.ClearIndicator>
    ),
  };
  const chakraStyles: ChakraStylesConfig<ComboBoxOption, boolean, any> = {
    container: (provided) => ({
      ...provided,
      borderTop: '1px solid white',
      borderLeft: '1px solid white',
      borderRight: '1px solid white',
      borderBottomRadius: 0,
      borderRadius: 0,
      div: {
        borderBottomRadius: 0,
        _focus: {
          borderBottomRadius: 'md',
        },
        _hover: {
          borderBottomRadius: 'md',
        },
      },
    }),
  };
  const dispatch = useAppDispatch();
  return (
    <WaitUntilLoaded
      loading={resourcesIsLoading && providersIsLoading}
      showErrorMessage={!(resourcesError == null || providersError == null)}
      errorMessage='Cannot load resources'>
      <Box mt={2}>
        <ComboBox
          size='lg'
          isLoading={resourcesIsLoading && providersIsLoading}
          onInputChange={setSearch}
          placeholder='Find a Provider/Resource'
          options={options
            .filter((a) => a.label.toLowerCase().includes(search.toLowerCase()))
            .slice(0, 10)}
          onSelection={(selection) => {
            // We only query encounters by resourceIds because the providerId from our Provider
            // lookup table will live in the resourceId of its associated encounter
            dispatch(
              resourceActions.setSelectedResourceIds({
                selectedResourceIDs: selection
                  ? selection.map((option) => parseInt(option.value))
                  : [],
              }),
            );
          }}
          isMulti={true}
          components={components}
          chakraStyles={chakraStyles}
        />
      </Box>
    </WaitUntilLoaded>
  );
}

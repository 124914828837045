import { Icon, IconButton } from '@chakra-ui/react';
import { BiTransferAlt } from 'react-icons/bi';
import {
  RecommendationType,
  type SubmitImmunizationInjectionInput,
  useSubmitImmunizationInjectionMutation,
} from '../../../../__generated__/graphql';
import { useCustomToast } from '../../../../hooks';

function invalidateCache(cache: any) {
  // Invalidate cache
  cache.evict({
    fieldName: 'getImmunizationInjectionPatient',
  });
  cache.evict({
    fieldName: 'getGrits',
  });
}

export function TransferIconButton({
  isDisabled,
  submitImmunizationInjectionInput,
}: {
  isDisabled?: boolean;
  submitImmunizationInjectionInput: SubmitImmunizationInjectionInput;
}) {
  const [submitImmunization, { loading }] = useSubmitImmunizationInjectionMutation();
  const toast = useCustomToast();

  function handleSubmitHistoricalImmunization() {
    submitImmunization({
      variables: {
        input: {
          ...submitImmunizationInjectionInput,
          patientImmunizationInjectionType: RecommendationType.Immunization,
          documentingFacilityId: 0,
          lotId: 0,
          administeredByUserId: 0,
          vaccinationGivenInThePast: true,
          sourceEncounterId: 0,
          sourceOfHistory: '01',
          statusId: 1, // Administered
        },
      },
      onError(err) {
        toast({
          id: 'submit-immunization-error',
          status: 'error',
          title: 'Error',
          duration: 5000,
          description: err.message,
        });
      },
      update: invalidateCache,
      onCompleted() {
        toast({
          id: 'submit-immunization-success',
          title: 'Success',
          description: `Successfully added ${submitImmunizationInjectionInput.immunizationName} to immunization history.`,
          status: 'success',
        });
      },
    });
  }

  return (
    <div>
      <IconButton
        isLoading={loading}
        isDisabled={isDisabled}
        aria-label='search-button'
        borderLeftRadius={0}
        variant='ghost'
        size='xs'
        colorScheme='brand'
        onClick={handleSubmitHistoricalImmunization}
        icon={<Icon boxSize={6} as={BiTransferAlt} />}
      />
    </div>
  );
}

import {
  Box,
  Flex,
  Tabs,
  Page,
  Logo,
  HStack,
  Center,
  LocationSelector,
  ProviderResourceSelector,
  TabPanels,
  TabList,
  TabPanel,
  Tab,
  IconButton,
} from '../../components';
import { EncounterCardList, MAMetrics, RefreshButton } from './components';
import { type PatientSearchScreenTabs } from '../../types';
import {
  appointmentsActions,
  selectors,
  uiActions,
  uiSlice,
  useAppDispatch,
  useAppSelector,
  worklistTodoActions,
} from '../../state-management';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { Icon } from '../../components/general/Icon';
import { useLeftNav } from '../../hooks';
import { useEffect } from 'react';

function TodoEncounters(props: any) {
  const activeTab = useAppSelector(selectors.getPatientSearchScreenActiveTab);

  if (activeTab !== 'To-Do') {
    return null;
  }

  return <EncounterCardList {...props} />;
}

function DoneEncounters(props: any) {
  const activeTab = useAppSelector(selectors.getPatientSearchScreenActiveTab);

  if (activeTab !== 'Done') {
    return null;
  }

  return <EncounterCardList {...props} />;
}

const tabConfig: PatientSearchScreenTabs[] = ['To-Do', 'Done'];

export function PatientSearchScreen() {
  const dispatch = useAppDispatch();
  const { onOpen } = useLeftNav();
  const activeTab = useAppSelector(selectors.getPatientSearchScreenActiveTab);

  useEffect(() => {
    // Clear previous order flow
    dispatch(uiActions.resetOrderFlow());
    dispatch(worklistTodoActions.resetWorklist());
    dispatch(appointmentsActions.reset());
  }, [dispatch]);

  function handleTableChange(index: number) {
    const activeTab = tabConfig[index];
    dispatch(
      uiSlice.actions.onTabChange({
        selectedTab: activeTab,
      }),
    );
  }

  return (
    <Page>
      <Box pb='lg'>
        <Flex my='md'>
          <HStack spacing='md'>
            <Center>
              <IconButton
                aria-label='Info'
                size='sm'
                colorScheme='transparent'
                icon={<Icon color='primary' boxSize={8} as={HiOutlineMenuAlt1} />}
                onClick={onOpen}
              />
            </Center>
            <Center>
              <Logo />
            </Center>
          </HStack>

          <Box flex='1' mx='md'>
            <LocationSelector />
          </Box>
          <Box flex='1'>
            <ProviderResourceSelector />
          </Box>
          <Center>
            <RefreshButton aria-label='Refresh button' />
          </Center>
        </Flex>
        <Tabs
          isFitted
          defaultIndex={activeTab === 'To-Do' ? 0 : 1}
          colorScheme='brand'
          onChange={handleTableChange}
          display='flex'
          flexDirection='column'
          overflow='auto'>
          <TabList>
            <Tab>To-Do</Tab>
            <Tab>Done</Tab>
          </TabList>

          <TabPanels overflow='auto'>
            <TabPanel>
              <TodoEncounters />
            </TabPanel>
            <TabPanel>
              <DoneEncounters />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <MAMetrics />
      </Box>
    </Page>
  );
}

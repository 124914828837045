import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DatePicker } from '../../../../components';
import { AsyncSelectField } from '../../../../features';
import { SearchIcon } from '@chakra-ui/icons';
import {
  type ImmunizationInjectionItem,
  type ImmunizationInjectionLookup,
  ImmunizationInjectionType,
  RecommendationType,
  type SubmitImmunizationInjectionInput,
  useGetImmunizationInjectionsQuery,
  useSubmitImmunizationInjectionMutation,
  useUpdateImmunizationInjectionMutation,
} from '../../../../__generated__/graphql';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCustomToast, usePatient } from '../../../../hooks';

type FormInputs = SubmitImmunizationInjectionInput;

function invalidateCache(cache: any) {
  // Invalidate cache
  cache.evict({
    fieldName: 'getImmunizationInjectionPatient',
  });
}

/**
 * When an immunization order is passed as a prop, the component is in edit mode.
 */
export function AddOrEditImmunizationHistoryButton({
  order,
}: {
  order?: ImmunizationInjectionItem | null;
}) {
  const isEditing = !!order;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const toast = useCustomToast();

  const { patientId } = usePatient();

  const defaultValues = {
    dateTimeAdministered: order?.dateTimeAdministered ?? '',
    notes: order?.notes ?? '',
    immunizationId: order?.immunizationId ?? 0,
    immunizationItemId: order?.immunizationItemId ?? 0,
    immunizationName: order?.immunizationName ?? '',
    patientImmunizationInjectionType:
      order?.patientImmunizationInjectionType ?? RecommendationType.Immunization,
  };
  const { handleSubmit, control, reset } = useForm<FormInputs>({
    defaultValues,
  });

  const [submitImmunization, { loading: isLoadingSubmitImmunization }] =
    useSubmitImmunizationInjectionMutation();

  const [updateImmunization, { loading: isLoadingUpdateImmunization }] =
    useUpdateImmunizationInjectionMutation();

  const [selectedDropdownItem, setSelectedOrderableItem] = useState<
    DropdownOption | undefined | null
  >();

  const { data: injectionData, loading: getImmunizationInjectionsLoading } =
    useGetImmunizationInjectionsQuery({
      variables: {
        contains: searchTerm ?? '',
        immunizationInjectionTypes: [ImmunizationInjectionType.Immunization],
      },
      skip: !searchTerm,
    });

  const immunizationInjectionLookupData = injectionData?.getImmunizationInjections ?? [];
  const dropdownItems = convertInjectionLookupItemToAsyncDropdownOptions(
    immunizationInjectionLookupData,
  );
  const selectedInjectionItem = immunizationInjectionLookupData.find(
    (item) => item.immunizationItemId === Number(selectedDropdownItem?.value),
  );

  const handleOnClose = () => {
    reset(defaultValues);
    setSelectedOrderableItem(undefined);
    onClose();
  };

  function handleAddImmunization(values: FormInputs) {
    if (selectedInjectionItem) {
      submitImmunization({
        variables: {
          input: {
            ...values,
            immunizationItemId: selectedInjectionItem.immunizationItemId,
            immunizationName: selectedInjectionItem.name,
            sourcePatientId: patientId,
            patientImmunizationInjectionType: RecommendationType.Immunization,
            documentingFacilityId: 0,
            lotId: 0,
            administeredByUserId: 0,
            vaccinationGivenInThePast: true,
            sourceEncounterId: 0,
            sourceOfHistory: '01',
            statusId: 1, // Administered
          },
        },
        onError(err) {
          toast({
            id: 'submit-immunization-error',
            status: 'error',
            title: 'Error',
            duration: 5000,
            description: err.message,
          });
        },
        update: invalidateCache,
        onCompleted: handleOnClose,
      });
    }
  }

  function handleUpdateImmunization(values: FormInputs) {
    // TODO: If the new injection picked is different from the old one, update the immunizationItemId
    updateImmunization({
      variables: {
        input: {
          ...values,

          sourcePatientId: order?.sourcePatientId ?? patientId,
          administeredByUserId: order?.administeredByUserId ?? 0,
          documentingFacilityId: order?.documentingFacilityId ?? 0,
          lotId: order?.lotId ?? 0,
          vaccinationGivenInThePast: !!order?.vaccinationGivenInThePast,
          statusId: order?.statusId ?? 1, // Administered,
          sourceOfHistory: order?.sourceOfHistory ?? '01',
          sourceEncounterId: order?.sourceEncounterId ?? 0,

          immunizationItemId:
            selectedInjectionItem?.immunizationItemId ?? values.immunizationItemId,
          immunizationName: selectedInjectionItem?.name ?? values.immunizationName,
        },
      },
      onError(err) {
        toast({
          id: 'submit-immunization-error',
          status: 'error',
          title: 'Error',
          duration: 5000,
          description: err.message,
        });
      },
      update: invalidateCache,
      onCompleted: handleOnClose,
    });
  }

  const onSubmit = isEditing
    ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleSubmit(handleUpdateImmunization)();
      }
    : handleSubmit(handleAddImmunization);

  return (
    <>
      {isEditing ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          variant='ghost'>
          Edit
        </Button>
      ) : (
        <Button onClick={onOpen}>Add Immunization History</Button>
      )}

      <Modal isOpen={isOpen} onClose={handleOnClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'Edit' : 'Add'} Immunization History</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing='md' align={'stretch'}>
              {!isEditing && (
                <Box mb='md'>
                  <AsyncSelectField
                    inputRightElement={<SearchIcon color='gray.300' />}
                    handleOptionSelect={(option) => {
                      setSelectedOrderableItem(option);
                    }}
                    inputProps={{
                      placeholder: 'Quick Find',
                      autoFocus: true,
                    }}
                    dropdownItems={dropdownItems}
                    handleInputChange={(val) => {
                      if (!val) return;
                      setSearchTerm(val);
                    }}
                    loading={getImmunizationInjectionsLoading}
                  />
                </Box>
              )}
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Text fontSize={'xl'} fontWeight={'bold'}>
                  {isEditing ? order?.immunizationName : selectedDropdownItem?.label}
                </Text>
                <div>
                  <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='given-in-past' mb='0' whiteSpace='nowrap'>
                      Given In Past
                    </FormLabel>
                    <Switch id='given-in-past' isChecked disabled />
                  </FormControl>
                </div>
              </Flex>

              <Controller
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Given Date/Time</FormLabel>
                    <DatePicker
                      placeholder='Date'
                      maxDate={new Date()}
                      value={field.value ? new Date(field.value) : null}
                      onChange={(date) => {
                        field.onChange(date?.toISOString());
                      }}
                    />
                  </FormControl>
                )}
                control={control}
                name='dateTimeAdministered'
              />

              <Controller
                render={({ field }) => (
                  <Textarea
                    placeholder='Comments'
                    rows={7}
                    {...field}
                    value={field.value ?? undefined}
                  />
                )}
                control={control}
                name='notes'
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={handleOnClose}>
              Back
            </Button>
            <Button
              isDisabled={!selectedInjectionItem && !order?.immunizationId}
              isLoading={isLoadingSubmitImmunization || isLoadingUpdateImmunization}
              onClick={onSubmit}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const convertInjectionLookupItemToAsyncDropdownOptions = (
  items?: ImmunizationInjectionLookup[],
): DropdownOption[] => {
  return (
    items?.map((i) => ({
      value: i.immunizationItemId.toString(),
      label: i.name,
    })) || []
  );
};

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { type ImmunizationInjectionItem } from '../../../../__generated__/graphql';
import { DisabledInjectionForm } from '../../../../features/DisabledInjectionForm';
import { formatDateNoUTC } from '../../../../utils';
import { DeleteImmunizationButton } from './DeleteImmunizationButton';
import { AddOrEditImmunizationHistoryButton } from './AddOrEditImmunizationHistoryButton';

export function RowWithDisabledInjectionFormModal({
  injection,
}: {
  injection: ImmunizationInjectionItem;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isPastInjection = injection.vaccinationGivenInThePast;

  return (
    <>
      <Tr
        key={injection.immunizationId}
        onClick={onOpen}
        _hover={{ cursor: 'pointer', bgColor: 'blue.50' }}>
        <Td>{injection.immunizationName}</Td>
        <Td>{injection.dose}</Td>
        <Td>
          {injection.dateTimeAdministered ? formatDateNoUTC(injection.dateTimeAdministered) : ''}
        </Td>
        <Td>{injection.injectionLocation}</Td>
        <Td>{injection.lotId}</Td>
        <Td>
          {isPastInjection && (
            <HStack>
              <AddOrEditImmunizationHistoryButton order={injection} />
              <DeleteImmunizationButton order={injection} />
            </HStack>
          )}
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <DisabledInjectionForm injection={injection} />
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

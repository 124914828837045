import { Search2Icon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useActiveEncounter, useCustomToast, usePatient } from '../../../hooks';
import { useMemo, useState } from 'react';
import { formatDate } from '../../../utils';
import { type Appointment } from '../../../types';
import { Spinner } from '../Spinner';
import { useSelector } from 'react-redux';
import { appointmentsActions, selectors, useAppDispatch } from '../../../state-management';
import { Avatar } from './UserInfo/UserInfo';
import { FaArrowRightArrowLeft, FaFileLines, FaFilePdf, FaPlus, FaMinus } from 'react-icons/fa6';
import {
  useAddPastResultToEncounterMutation,
  useRemovePastResultFromEncounterMutation,
  useTransferFutureOrderableItemMutation,
} from '../../../__generated__/graphql';

interface AllLabsProps {
  setSelectedLab: (lab: UILabItem) => void;
}

interface Labs {
  groupedLabs: GroupedLabs;
  attachedLabs: UILabItem[];
  transferredLabs: UILabItem[];
}

interface GroupedLabs {
  Lab: UILabItem[];
  'Diagnostic Imaging': UILabItem[];
  Procedure: UILabItem[];
}

export function AllLabs({ setSelectedLab }: AllLabsProps) {
  const [addPastResultToEncounter] = useAddPastResultToEncounterMutation();
  const [transferFutureOrderableItem, { loading: isTransferring }] =
    useTransferFutureOrderableItemMutation();

  const { patient, loading, refetch: refetchPatient } = usePatient();
  const { activeEncounter } = useActiveEncounter();

  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  const id = useSelector(selectors.getPatientId);
  const name = useSelector(selectors.getPatientFullName);
  const ageGenderBirthday = useSelector(selectors.getAgeGenderBirthday);
  const visitReason = useSelector(selectors.getVisitReason);
  const visitDate = useSelector(selectors.getVisitDate);

  const [search, setSearch] = useState('');
  const [futureOnly, setFutureOnly] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const subItems = [
    `Appt ${visitDate && new Date(visitDate).toLocaleString()}`,
    `${id} ${ageGenderBirthday}`,
    visitReason,
  ];

  const labs = useMemo(() => {
    if (!loading && patient.orderableItemHistory) {
      const loweredSearch = search.toLocaleLowerCase();
      const labs: Labs = {
        groupedLabs: {
          Lab: [],
          'Diagnostic Imaging': [],
          Procedure: [],
        },
        attachedLabs: [],
        transferredLabs: [],
      };

      for (const orderableItem of patient.orderableItemHistory) {
        const orderableItemData = {
          encounterID: orderableItem.encounterID ?? 0,
          orderableReportID: parseInt(orderableItem.orderableReportID!),
          name: orderableItem.orderableItemName ?? '',
          // Display 'Today' if order date is today
          date:
            new Date(orderableItem.orderDate).toDateString() === new Date().toDateString()
              ? 'Today'
              : formatDate(orderableItem.orderDate),
          status: orderableItem.orderTypeOccurrence
            ? // Take first letter of each word in status, uppercased
              orderableItem.orderTypeOccurrence
                .split(' ')
                .map((word) => word[0].toUpperCase())
                .join('')
            : '',
          hasDetailedReport: !!orderableItem.formattedReportId?.length,
          hasDocument: !!orderableItem.documentIds?.length,
        };

        // If Orderable Item is attached to Encounter, move it to different array
        // to be displayed separately
        if (
          activeEncounter?.attachedReportIds &&
          activeEncounter.attachedReportIds.includes(
            parseInt(orderableItem.orderableReportID ?? '0'),
          )
        ) {
          // Send to different arrays dependant on whether it is future order
          if (
            orderableItem.encounterID === activeEncounter.encounterID &&
            orderableItem.encounterID !== orderableItem.orderEncounterId
          ) {
            labs.transferredLabs.push(orderableItemData);
          } else {
            labs.attachedLabs.push(orderableItemData);
          }
          continue;
        }
        if (
          orderableItem.orderableItemType &&
          orderableItem.orderableItemName?.toLocaleLowerCase().includes(loweredSearch)
        ) {
          labs.groupedLabs[orderableItem.orderableItemType as keyof GroupedLabs].push(
            orderableItemData,
          );
        }
      }

      return futureOnly ? filterFutureGroupedLabs(labs) : labs;
    }
  }, [patient, loading]);

  return (
    <>
      {/* Inert UserInfo component */}
      <HStack whiteSpace='nowrap' w='fit-content' marginBottom='24px'>
        <Box>
          <Avatar minWidth={10} maxWidth={14} size='5.5vw' />
        </Box>
        <Box>
          <Text fontSize='md' fontWeight='bold'>
            {name}
          </Text>
          {subItems.map((item) => (
            <Text key={item} color='secondary' variant='body2-b' fontSize={10} lineHeight='short'>
              {item}
            </Text>
          ))}
        </Box>
      </HStack>

      <Text fontSize='2xl' as='b'>
        Patient Lab History
      </Text>

      {!(isTransferring || isRemoving) && (
        <>
          <SelectedLabList
            labs={labs?.attachedLabs ?? []}
            label='Applied'
            setSelectedLab={setSelectedLab}
            isRemoving={isRemoving}
            setIsRemoving={setIsRemoving}
            activeEncounter={activeEncounter}
          />
          <SelectedLabList
            labs={labs?.transferredLabs ?? []}
            label='Transferred'
            setSelectedLab={setSelectedLab}
            isRemoving={isRemoving}
            setIsRemoving={setIsRemoving}
            activeEncounter={activeEncounter}
          />
        </>
      )}

      {labs && !(isTransferring || isRemoving) ? (
        <>
          <Flex justifyContent='space-between'>
            <InputGroup margin='12px 0'>
              <InputRightElement>
                <Search2Icon />
              </InputRightElement>
              <Input
                placeholder='Search'
                focusBorderColor='brand.500'
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </InputGroup>

            <HStack spacing='sm' width='18%' justifyContent='flex-end'>
              <Text>Future Only</Text>
              <Switch
                onChange={(e) => {
                  setFutureOnly(e.target.checked);
                }}
              />
            </HStack>
          </Flex>

          <Box>
            <SimpleGrid columns={3} spacing={2} marginBottom='8px'>
              {Object.keys(labs.groupedLabs).map((labType) => {
                const labCount = labs.groupedLabs[labType as keyof GroupedLabs].length;

                return (
                  <Text key={labType} size='lg' as='b' marginLeft='1%'>
                    {labCount ? `${labType} - ${labCount}` : ''}
                  </Text>
                );
              })}
            </SimpleGrid>

            <SimpleGrid columns={3} spacing={2} maxHeight='65vh' overflowY='scroll' pt='xs' pb='sm'>
              {Object.keys(labs.groupedLabs).map((labType) => {
                const typedLabs = labs.groupedLabs[labType as keyof GroupedLabs];
                return (
                  <VStack key={labType} spacing={3} alignItems='flex-start'>
                    {typedLabs
                      .sort((a, b) => {
                        const aDate = new Date(a.date!).getTime();
                        const bDate = new Date(b.date!).getTime();
                        if (!aDate || !bDate) {
                          return -1;
                        }
                        if (aDate > bDate) {
                          return -1;
                        }
                        if (aDate < bDate) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((lab, index) => {
                        return (
                          <Flex key={`${labType}-${index}`} width='98%' marginLeft='1%'>
                            <LabItem lab={lab} setSelectedLab={setSelectedLab} />

                            <Button
                              variant='outline'
                              height='100%'
                              borderRadius='0 5px 5px 0'
                              onClick={() => {
                                function dispatchAddOrder() {
                                  dispatch(
                                    appointmentsActions.updateAttachedReportIds({
                                      attachedReportIds: activeEncounter?.attachedReportIds
                                        ? [
                                            ...activeEncounter.attachedReportIds,
                                            lab.orderableReportID,
                                          ]
                                        : [lab.orderableReportID],
                                    }),
                                  );
                                }
                                if (lab.status === 'F') {
                                  transferFutureOrderableItem({
                                    variables: {
                                      orderableReportID: lab.orderableReportID,
                                      encounterId: activeEncounter?.encounterID,
                                      futureOrder: false,
                                    },
                                    onError(err) {
                                      toast({
                                        id: 'transfer-order-error',
                                        status: 'error',
                                        title: 'Error',
                                        duration: 5000,
                                        description: err.message,
                                      });
                                    },
                                    update: (cache) => {
                                      // Invalidate cache
                                      cache.evict({
                                        fieldName: 'getEncounterOrders',
                                      });
                                    },
                                    onCompleted() {
                                      refetchPatient().then(() => {
                                        dispatchAddOrder();
                                        toast({
                                          id: 'transfer-order-success',
                                          description: 'Future order transferred',
                                          status: 'success',
                                        });
                                      });
                                    },
                                  });
                                } else {
                                  dispatchAddOrder();
                                  addPastResultToEncounter({
                                    variables: {
                                      pastResultData: {
                                        encounterID: activeEncounter?.encounterID ?? 0,
                                        orderableReportID: lab.orderableReportID,
                                      },
                                    },
                                  });
                                }
                              }}>
                              {lab.status === 'F' ? (
                                <Icon as={FaArrowRightArrowLeft} color='brand.500' />
                              ) : (
                                <Icon as={FaPlus} color='brand.500' />
                              )}
                            </Button>
                          </Flex>
                        );
                      })}
                  </VStack>
                );
              })}
            </SimpleGrid>
          </Box>
        </>
      ) : (
        <Box height='50vh'>
          <Flex height='100%' justifyContent='center' alignItems='center'>
            <HStack spacing='md'>
              <Text>{renderLoadingText(isTransferring, isRemoving)}</Text>
              <Spinner colorScheme='brand' />
            </HStack>
          </Flex>
        </Box>
      )}
    </>
  );
}

function renderLoadingText(isTransferring: boolean, isRemoving: boolean) {
  if (isTransferring) {
    return 'Transferring future Order to Treatment Plan';
  } else if (isRemoving) {
    return 'Removing transferred Order from Treatment Plan';
  } else {
    return 'Loading Labs';
  }
}

interface LabItemProps {
  lab: UILabItem;
  setSelectedLab: (lab: UILabItem) => void;
  isAttached?: boolean;
}

function LabItem({ lab, setSelectedLab, isAttached = false }: LabItemProps) {
  return (
    <Card
      width='100%'
      borderRadius={isAttached ? '0 5px 5px 0' : '5px 0 0 5px'}
      _hover={{ backgroundColor: 'brand.100' }}
      onClick={() => {
        setSelectedLab(lab);
      }}>
      <CardBody padding='12px'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text fontSize='sm' as='b' width='65%'>
            {lab.name}
          </Text>

          <Flex
            flexDirection='column'
            alignItems='flex-end'
            flex={1}
            justifyContent={'space-between'}
            alignSelf='stretch'>
            <HStack spacing={2}>
              {lab.hasDetailedReport && <Icon as={FaFileLines} color='brand.500' />}

              {lab.hasDocument && <Icon as={FaFilePdf} color='brand.500' />}

              <Text color='gray.400' fontSize='sm'>
                {lab.status}
              </Text>
            </HStack>

            <Text fontSize='sm'>{lab.date}</Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}

interface SelectedLabListProps {
  labs: UILabItem[];
  label: string;
  setSelectedLab: (lab: UILabItem) => void;
  isRemoving: boolean;
  setIsRemoving: (isRemoving: boolean) => void;
  activeEncounter?: Appointment;
}

function SelectedLabList({
  labs,
  label,
  setSelectedLab,
  setIsRemoving,
  activeEncounter,
}: SelectedLabListProps) {
  const { refetch: refetchPatient } = usePatient();

  const dispatch = useAppDispatch();
  const [removePastResultFromEncounter] = useRemovePastResultFromEncounterMutation();
  const [transferFutureOrderableItem] = useTransferFutureOrderableItemMutation();
  const toast = useCustomToast();

  if (labs.length) {
    return (
      <VStack alignItems='flex-start' marginTop='sm' marginBottom='md'>
        <Text fontSize='md' fontWeight='bold'>
          {label} to Current Encounter
        </Text>
        {labs.map((lab, index) => {
          return (
            <Flex key={`attached-lab-${index}`} width='100%' alignItems='stretch'>
              <Button
                height='auto'
                variant='outline'
                borderRadius='5px 0 0 5px'
                onClick={() => {
                  function dispatchRemoveOrder() {
                    dispatch(
                      appointmentsActions.updateAttachedReportIds({
                        attachedReportIds: activeEncounter?.attachedReportIds
                          ? activeEncounter.attachedReportIds.filter(
                              (id) => id !== lab.orderableReportID,
                            )
                          : [],
                      }),
                    );
                  }

                  if (label === 'Transferred') {
                    setIsRemoving(true);

                    transferFutureOrderableItem({
                      variables: {
                        orderableReportID: lab.orderableReportID,
                        encounterId: activeEncounter?.encounterID,
                        futureOrder: true,
                      },
                      onError(err) {
                        setIsRemoving(false);
                        toast({
                          id: 'transfer-order-error',
                          status: 'error',
                          title: 'Error',
                          duration: 5000,
                          description: err.message,
                        });
                      },
                      update: (cache) => {
                        // Invalidate cache
                        cache.evict({
                          fieldName: 'getEncounterOrders',
                        });
                      },
                      onCompleted() {
                        refetchPatient().then(() => {
                          dispatchRemoveOrder();
                          setIsRemoving(false);
                          toast({
                            id: 'remove-future-order-success',
                            title: 'Transferred order removed',
                            status: 'success',
                          });
                        });
                      },
                    });
                  } else {
                    dispatchRemoveOrder();
                    removePastResultFromEncounter({
                      variables: {
                        pastResultData: {
                          encounterID: activeEncounter?.encounterID ?? 0,
                          orderableReportID: lab.orderableReportID,
                        },
                      },
                    });
                  }
                }}>
                <Icon as={FaMinus} color='brand.500' />
              </Button>

              <LabItem isAttached lab={lab} setSelectedLab={setSelectedLab} />
            </Flex>
          );
        })}
      </VStack>
    );
  }

  return null;
}

// Filters grouped labs object down to only future orders
function filterFutureGroupedLabs(labs: Labs) {
  const { groupedLabs } = labs;
  Object.keys(groupedLabs).forEach((labType) => {
    const typedLabs = labs.groupedLabs[labType as keyof GroupedLabs];
    labs.groupedLabs[labType as keyof GroupedLabs] = typedLabs.filter((lab) => lab.status === 'F');
  });

  return labs;
}

// Mock data matching GetExternalMedsByPatient query return type
export const getExternalMedsByPatient = () => [
  {
    sourcePatientId: 12345,
    sourceEncounterId: 67890,
    drugName: 'Lisinopril',
    ndcCode: '68462-457-01',
    direction: 'Take 1 tablet by mouth daily',
    quantity: '30',
    writtenDate: '2024-02-01',
    lastFillDate: '2024-02-15',
    providerName: 'Dr. Sarah Smith',
    quantityCode1: '30',
    daysSupply: '30',
    formulation: 'Tablet',
    pharmacy: 'CVS Pharmacy #4321',
    refills: '3',
    primaryDiagnosis: 'Essential (primary) hypertension',
    refillRemaining: '2',
    pvdName: 'Dr. Sarah Smith, MD',
    secondaryDiagnosis: 'Type 2 diabetes mellitus',
    strength: '10mg',
    created: '2024-03-15T14:30:00Z',
  },
  {
    sourcePatientId: 12345,
    sourceEncounterId: 67890,
    drugName: 'Metformin',
    ndcCode: '65162-175-10',
    direction: 'Take 1 tablet by mouth twice daily with meals',
    quantity: '60',
    writtenDate: '2024-01-15',
    lastFillDate: '2024-03-01',
    providerName: 'Dr. Michael Johnson',
    quantityCode1: '60',
    daysSupply: '30',
    formulation: 'Tablet',
    pharmacy: 'Walgreens #1234',
    refills: '2',
    primaryDiagnosis: 'Type 2 diabetes mellitus',
    refillRemaining: '1',
    pvdName: 'Dr. Michael Johnson, MD',
    secondaryDiagnosis: 'Obesity',
    strength: '500mg',
    created: '2024-03-15T14:30:00Z',
  },
  {
    sourcePatientId: 12345,
    sourceEncounterId: 67891,
    drugName: 'Atorvastatin',
    ndcCode: '60505-2571-09',
    direction: 'Take 1 tablet by mouth at bedtime',
    quantity: '30',
    writtenDate: '2024-01-20',
    lastFillDate: '2024-02-20',
    providerName: 'Dr. Sarah Smith',
    quantityCode1: '30',
    daysSupply: '30',
    formulation: 'Tablet',
    pharmacy: 'CVS Pharmacy #4321',
    refills: '5',
    primaryDiagnosis: 'Hyperlipidemia',
    refillRemaining: '4',
    pvdName: 'Dr. Sarah Smith, MD',
    secondaryDiagnosis: 'Coronary artery disease',
    strength: '40mg',
    created: '2024-03-15T14:30:00Z',
  },
  {
    sourcePatientId: 12345,
    sourceEncounterId: 67891,
    drugName: 'Omeprazole',
    ndcCode: '60505-0127-01',
    direction: 'Take 1 capsule by mouth daily before breakfast',
    quantity: '30',
    writtenDate: '2024-02-10',
    lastFillDate: '2024-03-10',
    providerName: 'Dr. Michael Johnson',
    quantityCode1: '30',
    daysSupply: '30',
    formulation: 'Capsule',
    pharmacy: 'Walgreens #1234',
    refills: '3',
    primaryDiagnosis: 'Gastroesophageal reflux disease',
    refillRemaining: '2',
    pvdName: 'Dr. Michael Johnson, MD',
    secondaryDiagnosis: 'Chronic gastritis',
    strength: '20mg',
    created: '2024-03-15T14:30:00Z',
  },
];

export function getEncounters() {
  return {
    encounters: [
      {
        encounterID: 7908104,
        facilityID: 7,
        providerID: 569374,
        duration: '20 minutes',
        encounterType: null,
        startTime: '2025-02-07T16:00:00.000+00:00',
        endTime: '2025-02-07T16:20:00.000+00:00',
        clinicalTimeIn: null,
        clinicalTimeOut: null,
        clinicalStatusAfterCheckIn: '',
        visitType: 'LAB',
        roomNumber: '',
        visitReason: 'SCREENING',
        visitStatus: 'PEN',
        progressNoteLockedDateTime: null,
        patient: {
          patientID: 519858,
          dateOfBirth: '1977-03-01T00:00:00.000Z',
          firstName: 'TEST_Taron',
          lastName: 'Brigman',
          gender: 'MALE',
          insurance: 'Aetna Life Insurance',
        },
        resource: {
          id: 207775,
          name: 'IMG A Mammo Scrn 1',
        },
        attachedReportIds: [7921942, 7660751, 7921941],
      },
      {
        encounterID: 7908101,
        facilityID: 7,
        providerID: 569374,
        duration: '20 minutes',
        encounterType: null,
        startTime: '2025-02-07T16:00:00.000+00:00',
        endTime: '2025-02-07T16:20:00.000+00:00',
        clinicalTimeIn: null,
        clinicalTimeOut: null,
        clinicalStatusAfterCheckIn: '',
        visitType: 'NUR',
        roomNumber: '',
        visitReason: 'SCREENING',
        visitStatus: 'PEN',
        progressNoteLockedDateTime: null,
        patient: {
          patientID: 295309,
          dateOfBirth: '1954-05-23T00:00:00.000Z',
          firstName: 'TEST_Jerry',
          lastName: 'Gray',
          gender: 'MALE',
          insurance: 'United Healthcare Medicare Advant PPO',
        },
        resource: {
          id: 207775,
          name: 'IMG A Mammo Scrn 1',
        },
        attachedReportIds: null,
      },
      {
        encounterID: 7908099,
        facilityID: 7,
        providerID: 569374,
        duration: '20 minutes',
        encounterType: null,
        startTime: '2025-02-07T16:00:00.000+00:00',
        endTime: '2025-02-07T16:20:00.000+00:00',
        clinicalTimeIn: null,
        clinicalTimeOut: null,
        clinicalStatusAfterCheckIn: '',
        visitType: 'MAMMO',
        roomNumber: '',
        visitReason: 'SCREENING',
        visitStatus: 'PEN',
        progressNoteLockedDateTime: null,
        patient: {
          patientID: 400448,
          dateOfBirth: '1961-08-14T00:00:00.000Z',
          firstName: 'TEST_Linda',
          lastName: 'Hammond',
          gender: 'FEMALE',
          insurance: 'United Healthcare Choice Plus ',
        },
        resource: {
          id: 207775,
          name: 'IMG A Mammo Scrn 1',
        },
        attachedReportIds: null,
      },
    ],
    nextToken: null,
  };
}

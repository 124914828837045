import { Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  type HPICategoryDataItem,
  type EncounterHPIDataItem,
  type HPISymptom,
} from '../../../types';
import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { EncounterHPIDataItemModalContent } from './EncounterHPIDataItemModalContent';
import { Box } from '../../layout';
import _ from 'lodash';
import { useMAWorkflow } from '../../../hooks';

interface EncounterHPIDataItemModalProps {
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  categoryName: string;
  symptom: HPISymptom;
  onCloseModal: () => void;
  saveSymptom: (
    encounterHPIDataItem: EncounterHPIDataItem,
    symptom: HPISymptom,
    currentCategories: HPICategoryDataItem[],
  ) => void;
  nextHandler: (next: boolean, symptom: HPISymptom) => void;
  enableNext: boolean;
  enableBack: boolean;
}

export function EncounterHPIDataItemModal({
  encounterHPIDataItem: encounterHPIDataItemProp,
  symptom,
  categoryName,
  onCloseModal,
  saveSymptom,
  nextHandler,
  enableBack,
  enableNext,
}: EncounterHPIDataItemModalProps) {
  const [encounterHPIDataItem, setEncounterHPIDataItem] = useState<
    EncounterHPIDataItem | undefined
  >(encounterHPIDataItemProp || undefined);

  const currentCategories = useMAWorkflow();
  const selectedEncounterHPICategories = currentCategories.hpi.selectedEncounterHPICategories;

  useEffect(() => {
    setEncounterHPIDataItem(encounterHPIDataItemProp);
  }, [encounterHPIDataItemProp]);

  return (
    <>
      <ModalHeader>
        {categoryName} {'>'} {symptom.name}
      </ModalHeader>
      <ModalCloseButton />
      {/* must be overflow auto or else suggestions of a certain length will push the action buttons out of view */}
      <ModalBody sx={{ overflow: 'auto' }}>
        <EncounterHPIDataItemModalContent
          encounterHPIDataItem={encounterHPIDataItem}
          setEncounterHPIDataItem={setEncounterHPIDataItem}
          symptomOptions={symptom.symptomOptions}
        />
      </ModalBody>
      <ModalFooter justifyContent={enableNext || enableBack ? 'space-between' : 'flex-end'}>
        {(enableNext || enableBack) && (
          <Box>
            <Button
              isDisabled={!enableBack}
              mr='sm'
              variant='outline'
              onClick={() => {
                if (
                  encounterHPIDataItem &&
                  !_.isEqual(encounterHPIDataItem, encounterHPIDataItemProp)
                ) {
                  saveSymptom(encounterHPIDataItem, symptom, selectedEncounterHPICategories);
                }
                nextHandler(false, symptom);
              }}>
              Previous
            </Button>
            <Button
              isDisabled={!enableNext}
              variant='outline'
              onClick={() => {
                if (
                  encounterHPIDataItem &&
                  !_.isEqual(encounterHPIDataItem, encounterHPIDataItemProp)
                ) {
                  saveSymptom(encounterHPIDataItem, symptom, selectedEncounterHPICategories);
                }
                nextHandler(true, symptom);
              }}>
              Next
            </Button>
          </Box>
        )}
        <Box>
          <Button variant='ghost' onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (encounterHPIDataItem) {
                saveSymptom(encounterHPIDataItem, symptom, selectedEncounterHPICategories);
                onCloseModal();
              }
            }}>
            Save Note
          </Button>
        </Box>
      </ModalFooter>
    </>
  );
}

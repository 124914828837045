import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  type ImmunizationInjectionItem,
  useDeleteImmunizationInjectionMutation,
} from '../../../../__generated__/graphql';
import { useCustomToast } from '../../../../hooks';

export function DeleteImmunizationButton({ order }: { order: ImmunizationInjectionItem | null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderName = order?.immunizationName;
  const toast = useCustomToast();

  const [deleteImmunizationOrderableItem, { loading: isLoadingDeleteImmunizationOrderableItem }] =
    useDeleteImmunizationInjectionMutation();

  function handleDelete() {
    if (!order) {
      return;
    }

    deleteImmunizationOrderableItem({
      variables: { immunizationId: order.immunizationId ?? -1 }, // verify
      update: (cache) => {
        // Invalidate cache
        cache.evict({
          fieldName: 'getImmunizationInjectionPatient',
        });
        cache.evict({
          fieldName: 'getEncounterOrders',
        });
      },
      onCompleted: () => {
        toast({
          id: 'delete-immunization-order-error',
          title: 'Success',
          description: 'Item Deleted',
          status: 'success',
        });
        onClose();
      },
      onError: (error) => {
        toast({
          id: 'delete-immunization-order-success',
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
        });
      },
    });
  }

  return (
    <>
      <Button
        variant='ghost'
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}>
        Delete
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Remove ${orderName}?`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{`Are you sure you want to remove ${orderName}?`}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={'sm'} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete} isLoading={isLoadingDeleteImmunizationOrderableItem}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

import { Phq9ScoreCalc } from './phq9Score';

// same logic just need to change the fields we're watching
// this applies to both the phq2 and phq2-2015
export class Phq2ScoreCalc extends Phq9ScoreCalc {
  // we create this in the smart modal since it does not exist in the data
  computedField = 4946;

  public nextPageHandler = () => {
    return this.score && this.score > 0 ? { symptomId: 23454, categoryId: 272675 } : undefined;
  };
}

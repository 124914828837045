import {
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  VStack,
  // Button,
  HStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { WaitUntilLoaded } from '../../../../components';
import { TransferIconButton } from './TransferIconButton';
import { useGrits } from './useGrits';
import { type SubmitImmunizationInjectionInput } from '../../../../__generated__/graphql';

export function PublicHealth() {
  const { data: gritsData, loading: isLoadingGrits } = useGrits();
  const recommendations = gritsData?.getGrits?.recommendations || [];
  const historyItems = gritsData?.getGrits?.history || [];
  const reconciledHistoryItems = historyItems.filter((h) => h.isReconciled);

  return (
    <WaitUntilLoaded loading={isLoadingGrits}>
      <VStack spacing={'lg'} align={'stretch'}>
        {isEmpty(historyItems) ? (
          <Text>No items to be reconciled</Text>
        ) : (
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th color='secondary'>To be reconciled</Th>
                  <Th color='secondary'>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {historyItems
                  ?.filter((item) => !item.isReconciled)
                  .map((h) => {
                    const canReconcile = !isEmpty(h.immunizationLookups) && !h.isReconciled;
                    const immunization = h.immunizationLookups?.[0];
                    const submitImmunizationInjectionInput: SubmitImmunizationInjectionInput = {
                      immunizationItemId: immunization?.immunizationItemId ?? 0,
                      immunizationName: immunization?.name,
                      sourcePatientId: h.sourcePatientId,
                      dateTimeAdministered: h.givenDate ? new Date(h.givenDate).toISOString() : '',
                      // Just adding sourceEncounterId and statusId to make TS happy
                      sourceEncounterId: 0,
                      statusId: 1, // Administered
                    };

                    return (
                      <Tr key={`${h.cvxCode}-${h.givenDate}`}>
                        <Td>
                          <HStack spacing={'md'}>
                            <TransferIconButton
                              isDisabled={!canReconcile}
                              submitImmunizationInjectionInput={submitImmunizationInjectionInput}
                            />
                            <Text>{h.immunizationName}</Text>
                          </HStack>
                        </Td>
                        <Td>{h.givenDate}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        {isEmpty(recommendations) ? (
          <Text>No recommendations</Text>
        ) : (
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th color='secondary'>Recommendations</Th>
                  <Th color='secondary'>Due date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recommendations?.map((h) => {
                  return (
                    <Tr key={h.immunizationName}>
                      <Td>{h.immunizationName}</Td>
                      <Td>{h.dueDate}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        {isEmpty(reconciledHistoryItems) ? (
          <Text>No reconciled items</Text>
        ) : (
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th color='secondary'>Reconciled</Th>
                  <Th color='secondary'>Due date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {reconciledHistoryItems?.map((h) => {
                  return (
                    <Tr key={`${h.cvxCode}-${h.givenDate}`}>
                      <Td>
                        <Text>{h.immunizationName}</Text>
                      </Td>
                      <Td>{h.givenDate}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>
    </WaitUntilLoaded>
  );
}

import {
  type HpiSmartFormEntity,
  type HpiStructuredData,
} from '../../../../../__generated__/graphql';

type CalculatorFormula<T> = (responses: T[]) => string | number | undefined;

// holds the logic for calculating a field
// some of our fields are 'computed' based on other fields
// ie a PHQ9 score field is calculated based on the answers to the PHQ9 questions
// this class holds the logic for calculating a field
// and keeps a bunch of the logic out of the react components
// if we need a new calculator we add it as a new class extending Calculator
// and can use it in the useScoring hook
abstract class Calculator<SmartFormType, ResponseType> {
  // this is the field we're automatically updating
  // it should be disabled in the ui
  computedField: number = 0;
  // the smart forms provided by the hpi symptom
  smartForms: SmartFormType[];
  // the id unique id field of the smart form
  smartFormMatchId: keyof SmartFormType = undefined!;
  responseMatchId: keyof ResponseType = undefined!;
  // what field do we get the value of the response from?
  responseTypeValue: keyof ResponseType = undefined!;
  score: number | undefined;

  //   check if this calculator should be used against the provided smartforms
  public isActive() {
    const isIt = this.smartForms.some((s) => s[this.smartFormMatchId] === this.computedField);
    return isIt;
  }

  // 'protected' means we can access this in subclasses
  // but not outside of the class once instantiated. use this since
  // we want to define a unique formula, but they should only access
  // updated values through the 'getValue' method
  protected abstract formula: CalculatorFormula<ResponseType>;

  // check the current value of the computed field in the responses
  // can use to determine if we need to update the value
  private readonly updateCurrentResponseValue = (responses: ResponseType[]) => {
    return responses.find((r) => r?.[this.responseMatchId] === this.computedField)?.[
      this.responseTypeValue
    ];
  };

  // optionally can have a nextpage handler
  // this is used in the phq2 forms to determine if we should go navigate to
  // the phq9
  public nextPageHandler: () => { symptomId: number; categoryId: number } | undefined = () => {
    return undefined;
  };

  // the main method to get the current value of the computed field
  public getValue = (responses: ResponseType[]) => {
    const currentValue = this.updateCurrentResponseValue(responses);
    const score = this.formula(responses);
    const hasUpdate = score !== undefined && `${currentValue}` !== `${score}`;
    return {
      currentValue: this.updateCurrentResponseValue(responses),
      score: this.formula(responses),
      hasUpdate,
    };
  };

  constructor(smartForms: SmartFormType[]) {
    this.smartForms = smartForms;
  }
}

export abstract class HpiCalculator extends Calculator<HpiSmartFormEntity, HpiStructuredData> {
  smartFormMatchId: keyof HpiSmartFormEntity = 'structureDataDetailID';
  responseMatchId: keyof HpiStructuredData = 'hpiStructuredDataDetailId';
  responseTypeValue: keyof HpiStructuredData = 'value';
}

import { Checkbox, Input } from '@chakra-ui/react';
import { Button, ButtonGroup, HStack, Tag, Text, Textarea, VStack, Wrap, WrapItem } from '../..';
import { AsyncSelectField } from '../../../features';
import { type EncounterHPIDataItem, type HPISymptom } from '../../../types';
import CustomRadio from '../../form/CustomRadio/CustomRadio';
import { useEffect, useRef } from 'react';
import { injectSuggestionTagForInput } from '../../../utils';

interface EncounterHPIDataItemModalContentProps {
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  setEncounterHPIDataItem: (encounterHPIDataItem: EncounterHPIDataItem) => void;
  symptomOptions: HPISymptom['symptomOptions'] | undefined;
}

export function EncounterHPIDataItemModalContent({
  encounterHPIDataItem,
  setEncounterHPIDataItem,
  symptomOptions,
}: EncounterHPIDataItemModalContentProps) {
  const noteInputRef = useRef<HTMLTextAreaElement>(null);
  const initialNoteHighlight = useRef(false);

  // Handle the initial data load and highlight of text
  useEffect(() => {
    if (!initialNoteHighlight.current && encounterHPIDataItem) {
      initialNoteHighlight.current = true;
      noteInputRef.current?.select(); // highlight notes
    }
  }, [encounterHPIDataItem]);

  // hardcode duration Options for now, until API is ready
  let durationOptions: DropdownOption[] = [
    { value: '1 day', label: '1 day' },
    { value: '1 month', label: '1 month' },
    { value: '1 week', label: '1 week' },
    { value: '2', label: '2' },
    { value: '2 months', label: '2 months' },
    { value: '2 weeks', label: '2 weeks' },
    { value: '2-3 days', label: '2-3 days' },
    { value: '3', label: '3' },
    { value: '3 months', label: '3 months' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: 'days', label: 'days' },
    { value: 'hours', label: 'hours' },
    { value: 'less than', label: 'less than' },
    { value: 'less than 6 months', label: 'less than 6 months' },
    { value: 'minutes', label: 'minutes' },
    { value: 'months', label: 'months' },
    { value: 'more than', label: 'more than' },
    { value: 'more than 10 years', label: 'more than 10 years' },
    { value: 'more than 2 years', label: 'more than 2 years' },
    { value: 'more than 5 years', label: 'more than 5 years' },
    { value: 'more than 6 months', label: 'more than 6 months' },
    { value: 'seconds', label: 'seconds' },
    { value: 'weeks', label: 'weeks' },
  ];

  const suggestions = symptomOptions?.filter(
    (so) => !so.hpiSymptomOptionStructuredDataList?.length,
  );
  const structuredData = symptomOptions?.filter(
    (so) => !!so.hpiSymptomOptionStructuredDataList?.length,
  );

  const hasMultiSelect = !!structuredData?.find(
    (so) => so.isMultiSelect,
  );


  const handleTagClick = (tagName: string) => {
    const result = injectSuggestionTagForInput(
      noteInputRef,
      encounterHPIDataItem?.symptomNotes ?? undefined,
      tagName,
    );

    setEncounterHPIDataItem({
      ...encounterHPIDataItem,
      symptomNotes: result,
    });
  };

  const handleAddDurationUnit = (unit: string) => {
    if (!encounterHPIDataItem?.duration || encounterHPIDataItem?.duration.trim() === '') {
      setEncounterHPIDataItem({
        ...encounterHPIDataItem!,
        duration: `1 ${unit}`,
      });
    } else {
      const firstNumberMatch = encounterHPIDataItem?.duration.match(/\d+/);
      if (firstNumberMatch != null) {
        setEncounterHPIDataItem({
          ...encounterHPIDataItem,
          duration: `${firstNumberMatch[0]} ${unit}`,
        });
      }
    }
  };

  const handleStructuredDataChange = ({
    optionID,
    notes,
    value,
    remove,
  }: {
    optionID: number;
    notes: string;
    value: string;
    remove: boolean;
  }) => {
    const isMultiSelect = !!structuredData?.find(
      (so) => so.optionID === optionID,
    )?.isMultiSelect;

    const hasItemAlready = encounterHPIDataItem?.hpiStructuredData?.find(
      (sd) => sd.hpiStructuredDataDetailId === optionID,
    );

    // delete
    if (remove) {
      setEncounterHPIDataItem({
        ...encounterHPIDataItem!,
        hpiStructuredData: encounterHPIDataItem?.hpiStructuredData?.filter(
          (sd) => sd.hpiStructuredDataDetailId !== optionID || sd.value !== value,
        ),
      });
    }

    // update
    if (hasItemAlready && !isMultiSelect) {
      setEncounterHPIDataItem({
        ...encounterHPIDataItem!,
        hpiStructuredData: encounterHPIDataItem?.hpiStructuredData?.map((sd) =>
          sd.hpiStructuredDataDetailId === optionID ? { ...sd, notes, value } : sd,
        ),
      });
    } else {
      setEncounterHPIDataItem({
        ...encounterHPIDataItem!,
        hpiStructuredData: [
          ...(encounterHPIDataItem?.hpiStructuredData || []),
          { hpiStructuredDataDetailId: optionID, notes, value },
        ],
      });
    }
  };

  durationOptions =
    encounterHPIDataItem?.duration !== null && encounterHPIDataItem?.duration !== undefined
      ? durationOptions.filter((x) =>
          x.value.toLowerCase().includes((encounterHPIDataItem.duration || '').toLowerCase()),
        )
      : durationOptions;

  return (
    <VStack spacing='md' alignItems='flex-start'>
      <HStack spacing='sm' w='full'>
        <CustomRadio
          options={[
            { name: 'Complains', value: 'true' },
            { name: 'Denies', value: 'false' },
          ]}
          key={encounterHPIDataItem?.hpiSymptomId} // this is to force re-render; otherwise, stale defaultValue from one modal will be incorrectly set for everything else.
          as='vertical'
          defaultValue={String(encounterHPIDataItem?.complainsDenies)}
          onChange={(val) => {
            const res = val === undefined ? undefined : val === 'true';
            setEncounterHPIDataItem({
              ...encounterHPIDataItem,
              // complainsDenies: val ? Boolean(val) : undefined,
              complainsDenies: res,
            });
          }}
        />
        {!structuredData?.length && (
          <AsyncSelectField
            openOnFocus
            handleOptionSelect={(option: DropdownOption) => {
              setEncounterHPIDataItem({
                ...encounterHPIDataItem!,
                duration: option.value,
              });
            }}
            dropdownItems={durationOptions}
            inputProps={{
              value: encounterHPIDataItem?.duration || '',
              placeholder: 'Duration',
            }}
            handleInputChange={(inputValue) => {
              setEncounterHPIDataItem({
                ...encounterHPIDataItem!,
                duration: inputValue || '',
              });
            }}
          />
        )}

        {!structuredData?.length && (
          <ButtonGroup variant='outline'>
            <Button
              onClick={() => {
                handleAddDurationUnit('days');
              }}>
              Days
            </Button>
            <Button
              onClick={() => {
                handleAddDurationUnit('weeks');
              }}>
              Weeks
            </Button>
            <Button
              onClick={() => {
                handleAddDurationUnit('months');
              }}>
              Months
            </Button>
            <Button
              onClick={() => {
                handleAddDurationUnit('years');
              }}>
              Years
            </Button>
          </ButtonGroup>
        )}
      </HStack>

      <Textarea
        ref={noteInputRef}
        value={encounterHPIDataItem?.symptomNotes || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          if (encounterHPIDataItem != null) {
            setEncounterHPIDataItem({
              ...encounterHPIDataItem,
              symptomNotes: e.target.value,
            });
          }
        }}
        rows={4}
        placeholder='Note'
      />

      {structuredData?.map((sd) => {
        if (!sd) return null;

        // with multi-select we may have multiple responses for the same option
        const responses = encounterHPIDataItem?.hpiStructuredData?.filter(
          (_sd) => _sd.hpiStructuredDataDetailId === sd.optionID,
        );

        const responseWithNote = responses?.find((r) => r.notes);

        return (
          <VStack key={sd.optionID} w='full'>
            <Text fontWeight='bold' bg='gray.100' w='full' p={1}>
              {sd.name}
            </Text>
            <Input
              value={responseWithNote?.notes ?? ''}
              placeholder='Notes'
              onChange={(e) => {
                handleStructuredDataChange({
                  optionID: sd.optionID!,
                  notes: e.target.value,
                  value: responseWithNote?.value ?? "",
                  remove: false,
                });
              }}
            />
            {sd?.hpiSymptomOptionStructuredDataList?.map((sdl, i) => {
              const isChecked = !!responses?.some(res => res.value === `${i}`);
              // get the value for each option since multi-select may be enabled
              return (
                <Checkbox
                  w='full'
                  key={sdl.optionID}
                  onChange={() => {
                    handleStructuredDataChange({
                      optionID: sd.optionID!,
                      notes: responseWithNote?.notes ?? '',
                      value: `${i}`,
                      remove: isChecked,
                    });
                  }}
                  isChecked={isChecked}>
                  {sdl?.name}
                </Checkbox>
              );
            })}
          </VStack>
        );
      })}

      {!!structuredData?.length && !hasMultiSelect && (
        <Text w='full' bg='gray.100' p={1} color='brand.500' fontWeight='bold'>
          Total Score:{' '}
          {encounterHPIDataItem?.hpiStructuredData?.reduce((acc, curr) => {
            const val = curr.value ? Number(curr.value) : 0;
            return acc + val;
          }, 0)}
        </Text>
      )}

      {suggestions?.length && !structuredData?.length && (
        <VStack w='full' alignItems='flex-start'>
          {suggestions?.length && <Text>Suggestions:</Text>}

          <Wrap spacing='sm'>
            {suggestions?.map((x) => (
              <WrapItem key={x.optionID || ''}>
                <Tag
                  onClick={() => {
                    handleTagClick(x.name || '');
                  }}
                  variant='outline'
                  borderRadius='full'
                  colorScheme='brand'
                  sx={{ cursor: 'pointer' }}>
                  {x.name}
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </VStack>
      )}
    </VStack>
  );
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ResourceState {
  selectedResourceIDs: number[];
}

const initialState: ResourceState = {
  selectedResourceIDs: [],
};

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setSelectedResourceIds(state, action: PayloadAction<{ selectedResourceIDs: number[] }>) {
      state.selectedResourceIDs = action.payload.selectedResourceIDs;
    },
  },
});

export const resourceActions = resourceSlice.actions;

export default resourceSlice.reducer;

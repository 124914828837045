import { ComboBox } from '../../../../../components';
import {
  getOpenTEsFilterState,
  openTEsFilterActions,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useGetLocationsQuery } from '../../../../../__generated__/graphql';
import { LOCATION_LIMIT } from '../../../../../constants';

// TODO: Make this a reusable component
export function Facilities() {
  const { data, loading } = useGetLocationsQuery({ variables: { limit: LOCATION_LIMIT } });

  const locations = data?.getLocations?.locations?.map((l) => ({
    label: l.locationName,
    value: l.locationID.toString(),
  }));

  const filterState = useAppSelector(getOpenTEsFilterState);
  const dispatch = useDispatch();

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Facilities'
      options={locations}
      onSelection={(facilities) => {
        const update = openTEsFilterActions.onFilterStateChange({ facilities });
        dispatch(update);
      }}
      defaultValue={filterState.facilities}
      isMulti
    />
  );
}

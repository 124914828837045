import { type ElementType, lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth, useCustomToast } from '../hooks';
import {
  ReviewOrderScreen,
  PatientViewScreen,
  CustomerFeedbackScreen,
  ListEncountersScreen,
  EncounterScreen,
  OrderFlowScreen,
  OpenTEScreen,
  OrderLabsScreen,
} from '../screens';
import { CumulativeReportScreen } from '../screens/ProviderWorkflowScreens/CumulativeReportScreen';
import { EncounterTemplateScreen } from '../screens/EncounterTemplateScreen/EncounterTemplateScreen';
import { PastEncounterDetailsScreen } from '../screens/PastEncounterDetailsScreen';
import { getAppVersion } from '../constants';
import { locationActions, useAppDispatch } from '../state-management';

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

// Avoiding importing all components / import component as we need
// By declaring each component as lazy promise so that it gets imported on the spot
// only when that component is being routed to
const { NavLayout, PublicLayout, PatientSearchScreen, LoginScreen } = lazyLoadingComponents();

export function AppRoutes() {
  const { roles } = useAuth();
  const providerWorkflowRoles: Role[] = ['provider', 'devs'];

  return useRoutes([
    // NavLayout: this requires auth check
    {
      path: '/',
      element: (
        <AuthCheck>
          <VersionCheck>
            <NavLayout />
          </VersionCheck>
        </AuthCheck>
      ),
      children: [
        {
          path: '/',
          element: <PatientSearchScreen />,
        },
        {
          path: 'review-order',
          element: <ReviewOrderScreen />,
        },
        {
          path: 'patient-view',
          element: <PatientViewScreen />,
        },
        {
          path: 'feedback',
          element: <CustomerFeedbackScreen />,
        },
        {
          path: 'past-encounter/:encounterId',
          element: <PastEncounterDetailsScreen />,
        },
        {
          path: 'providers',
          children:
            roles && providerWorkflowRoles.some((x) => roles.includes(x))
              ? [
                  {
                    index: true,
                    element: <ListEncountersScreen />,
                  },
                  {
                    path: ':encounterId',
                    element: <EncounterScreen />,
                  },
                  {
                    path: ':patientId/open',
                    element: <OpenTEScreen />,
                  },
                  {
                    path: ':patientId/cumulative-report',
                    element: <CumulativeReportScreen />,
                  },
                  {
                    path: ':encounterId/open/labs',
                    element: <OrderLabsScreen />,
                  },
                ]
              : [],
        },
        {
          path: 'order-flow',
          element: <OrderFlowScreen />,
        },
        {
          path: 'encounter-template',
          element: <EncounterTemplateScreen />,
        },
      ],
    },
    // PublicLayout
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          path: '/login',
          element: <LoginScreen />,
        },
      ],
    },
  ]);
}

// Authentication wrapper: checks if user is authenticated, if so, proceed as usualy, otherwise direct to login
function AuthCheck({ children }: any) {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to='login' />;
  }
}

// Version check - allows us to purge Redux state on version change without breaking app
function VersionCheck({ children }: any) {
  const dispatch = useAppDispatch();
  const localStorageVersion = window.localStorage.getItem('version');
  const currentVersion = getAppVersion();
  const toast = useCustomToast();

  if (localStorageVersion !== currentVersion) {
    // Rehydrates last selected location from localStorage
    const localStorageSelectedLocation = window.localStorage.getItem('selectedLocationID');
    const selectedLocationID = localStorageSelectedLocation
      ? parseInt(localStorageSelectedLocation)
      : 0;
    dispatch(locationActions.onLocationChange({ selectedLocationID }));

    // Notify user of update
    toast({
      id: 'app-update-success',
      title: 'App Updated',
      description: `Ensomata Application has been updated to v${currentVersion}`,
      status: 'success',
      duration: 3000,
    });

    // Current version should always exist, we just use ?? to appease Typescript
    window.setTimeout(() => {
      window.localStorage.setItem('version', currentVersion ?? '0.0.0');
    }, 1);

    // Navigate to homescreen
    return <Navigate to='/' replace={true} />;
  }

  return <>{children}</>;
}

function lazyLoadingComponents() {
  const NavLayout = Loadable(
    lazy(
      async () =>
        await import('../layouts').then((module) => ({
          default: module.NavLayout,
        })),
    ),
  );

  const PublicLayout = Loadable(
    lazy(
      async () =>
        await import('../layouts').then((module) => ({
          default: module.PublicLayout,
        })),
    ),
  );

  const PatientSearchScreen = Loadable(
    lazy(
      async () =>
        await import('../screens').then((module) => ({
          default: module.PatientSearchScreen,
        })),
    ),
  );

  const LoginScreen = Loadable(
    lazy(
      async () =>
        await import('../screens').then((module) => ({
          default: module.LoginScreen,
        })),
    ),
  );

  return {
    NavLayout,
    PublicLayout,
    PatientSearchScreen,
    LoginScreen,
  };
}

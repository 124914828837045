import { type DiagnosisUI } from '../types';

let IS_LOCAL_MOCK_ON = false; // Toggle this to show local mock in app (no auth + fixture api responses)
// IS_LOCAL_MOCK_ON = true;
IS_LOCAL_MOCK_ON = localStorage.getItem('USE_LOCAL_MOCK') !== null ?? false;

export const DEBOUNCE_MILLISECONDS = 300;

export const IS_LOCAL = process.env.NODE_ENV === 'development';
export const USE_LOCAL_MOCK = IS_LOCAL_MOCK_ON && IS_LOCAL;

export const ENCOUNTERS_PER_PAGE = 15;
export const PROVIDER_WORKFLOW_ENCOUNTER_PAGE_SIZE = 15;

export const IMMUNIZATION_DIAGNOSIS_CODE = 'Z23';
export const IMMUNIZATION_DIAGNOSIS_NAME = `${IMMUNIZATION_DIAGNOSIS_CODE} Encounter for Immunization`;
export const IMMUNIZATION_DIAGNOSIS: DiagnosisUI = {
  diagnosisName: IMMUNIZATION_DIAGNOSIS_NAME,
  icdCode: IMMUNIZATION_DIAGNOSIS_CODE,
};

export const LOCATION_LIMIT = 40;

export const FASTING_MAP: Record<number, string> = {
  0: 'Fasting',
  1: 'Not Fasting',
  2: 'Not Recorded',
};

export const isLocalhostWeb = Boolean(
  window?.location?.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window?.location?.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window?.location?.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

type APP_MODE = 'local_web' | 'local_pwa' | 'prod' | 'dev' | 'ma_workflow';

export function getAppMode(): APP_MODE {
  if (window?.location?.hostname?.includes('192.168')) {
    return 'local_pwa';
  }

  return process.env.REACT_APP_MODE as APP_MODE;
}

export function getAppVersion() {
  return process.env.REACT_APP_VERSION;
}

export function getRedirectLink() {
  return window.location.origin;
}

export const IS_PROD = getAppMode() === 'prod';

export const DEV_API_URL =
  process.env.REACT_APP_DEV_API_URL || 'https://api.dev.ensomata.io/graphql';

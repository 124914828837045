import { useGetGritsQuery } from '../../../../__generated__/graphql';
import { usePatient } from '../../../../hooks';

export function useGrits() {
  const { patientId } = usePatient();
  const query = useGetGritsQuery({ variables: { patientId } });
  // const query = useGetGritsQuery({ variables: { patientId: 273901 } }); // TEST USER

  return query;
}

import { selectors, useAppSelector } from '../state-management';
import {
  type Encounter,
  type UpdateEncounterInput,
  useGetEncountersQuery,
  useUpdateEncounterMutation,
} from '../__generated__/graphql';
import { ENCOUNTERS_PER_PAGE } from '../constants';
import { NetworkStatus } from '@apollo/client';
import _ from 'lodash';

export function useEncounters({ includeLabs = false } = {}) {
  const locationID = useAppSelector(selectors.getSelectedLocationID);
  const activeTab = useAppSelector(selectors.getPatientSearchScreenActiveTab);
  const isCompleted = activeTab === 'Done';
  const limit = ENCOUNTERS_PER_PAGE;
  const selectedProviderIds = useAppSelector(selectors.getSelectedProviderIDs);
  const selectedResourceIDs = useAppSelector(selectors.getSelectedResourceIDs);

  const {
    fetchMore,
    networkStatus,
    data,
    loading: isLoading,
    error,
    refetch,
  } = useGetEncountersQuery({
    variables: {
      isCompleted,
      locationID,
      limit,
      providerIDs: selectedProviderIds,
      resourceIDs: selectedResourceIDs,
      // If the useEncounters method starts being used elsewhere where
      // encounters of visitType = LAB are necessary, this boolean needs
      // to be moved into state or a parameter of the hook
      includeLabs,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [handleUpdateEncounter, { loading: isUpdatingEncounter, error: updateEncounterError }] =
    useUpdateEncounterMutation({
      update: (cache, x) => {
        cache.modify({
          fields: {
            getEncounters({
              encounters,
              nextToken,
            }: {
              encounters: Encounter[];
              nextToken: string;
            }) {
              const updatedEncounter = x.data?.updateEncounter;
              const updatedEncounters = encounters.map((encounter) => {
                if (encounter.encounterID === updatedEncounter?.encounterID) {
                  return {
                    ...updatedEncounter,
                    roomNumber: updatedEncounter?.roomNumber || encounter.roomNumber,
                  };
                }
                return encounter;
              });

              return { encounters: updatedEncounters, nextToken };
            },
          },
        });
      },
    });

  function onFetchNextPage(nextToken?: string) {
    fetchMore({
      variables: { nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldEncounters = previousResult.getEncounters?.encounters
          ? previousResult.getEncounters.encounters
          : [];
        const newEncounters = fetchMoreResult.getEncounters?.encounters
          ? fetchMoreResult.getEncounters.encounters
          : [];

        return {
          ...previousResult,
          getEncounters: {
            ...fetchMoreResult.getEncounters,
            nextToken: !fetchMoreResult.getEncounters?.nextToken
              ? null
              : fetchMoreResult.getEncounters.nextToken,
            encounters: [...oldEncounters, ...newEncounters],
          },
        };
      },
    });
  }

  async function updateEncounter(encounterId: number, encounter: Partial<UpdateEncounterInput>) {
    const originalEncounter = data?.getEncounters?.encounters?.find(({ encounterID }) => {
      return encounterID === encounterId;
    });
    if (originalEncounter?.patient && !_.isNil(originalEncounter.providerID)) {
      const updateObj: UpdateEncounterInput = {
        sourceEncounterId: encounterId,
        sourcePatientId: originalEncounter.patient.patientID,
        providerId: originalEncounter.providerID,
      };
      if (!_.isNil(encounter.roomNumber)) {
        updateObj.roomNumber = encounter.roomNumber;
      }
      if (!_.isNil(encounter.clinicalTimeIn)) {
        updateObj.clinicalTimeIn = encounter.clinicalTimeIn;
      }
      if (!_.isNil(encounter.clinicalTimeOut)) {
        updateObj.clinicalTimeOut = encounter.clinicalTimeOut;
      }
      if (!_.isNil(encounter.duration)) {
        updateObj.duration = encounter.duration;
      }
      if (!_.isNil(encounter.clinicalStatusAfterCheckIn)) {
        updateObj.clinicalStatusAfterCheckIn = encounter.clinicalStatusAfterCheckIn;
      }
      return handleUpdateEncounter({
        variables: {
          encounterId,
          encounterInput: updateObj,
        },
      });
    }
    throw new Error('Missing patient id');
  }

  return {
    onFetchNextPage,
    data: data?.getEncounters,
    isLoading,
    error,
    isFetching: NetworkStatus.fetchMore === networkStatus,
    isInitialLoading: NetworkStatus.loading === networkStatus,
    updateEncounter,
    networkStatus,
    isUpdatingEncounter,
    updateEncounterError,
    refetchEncounters: refetch,
  };
}

import { isEmpty, isNumber } from 'lodash';
import {
  OrderMetricRecommendationType,
  RecommendationType,
  useAddOrderableItemMutation,
  useSubmitImmunizationInjectionMutation,
  type AddOrderableItemMutationVariables,
  type ImmunizationInjectionLookup,
  type Problem,
} from '../../../__generated__/graphql';
import { IS_LOCAL } from '../../../constants';
import {
  useCustomToast,
  useEncounter,
  useFacility,
  useMAWorkflow,
  usePatient,
} from '../../../hooks';
import {
  selectors,
  useAppDispatch,
  useAppSelector,
  worklistTodoActions,
  type EditedOrder,
} from '../../../state-management';
import { type OptionItem, type OrderableItemLookupUI } from '../../../types';
import {
  autoPopulateOrderableItemReason,
  isRecommendationInjectionOrImmunization,
  isRecommendationLab,
} from '../../../utils';

export function useAddOrder() {
  const { facilityId: facilityID } = useFacility();
  const { patientId } = usePatient();
  const { activeEncounterID: encounterID } = useEncounter();
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const orderingProviderID = useAppSelector(selectors.getActiveProviderID);
  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  // Mutations
  const [submitImmunization, { loading: isLoadingSubmitImmunization }] =
    useSubmitImmunizationInjectionMutation();
  const [handleAddOrderableItem, { loading: isLoadingAddOrderableItem }] =
    useAddOrderableItemMutation();

  async function handleAddOrder(
    order: EditedOrder,
    { onCompleted }: { onCompleted?: () => void } = {},
  ) {
    const orderableItemTypeId = order?.alert?.orderableItemTypeId;
    const recommendation = order?.alert;
    const orderMetricRecommendationType =
      order?.alert.orderMetricRecommendationType || OrderMetricRecommendationType.Adhoc;
    const futureOrder = recommendation?.futureOrder;
    const fasting = recommendation?.fasting;
    const orderAssessments = order?.orderAssessments;
    const isInjectionOrImmunization = isRecommendationInjectionOrImmunization(order);
    const assignedToUserEmail = IS_LOCAL ? 'tdale@elfp.com' : 'jbaker@elfp.com'; // TODO: Real user email
    const documentingFacilityID = facilityID;
    const selectedOrderableItem = order.selectedOrderableItem;
    const orderableItemId = selectedOrderableItem?.orderableItemId
      ? selectedOrderableItem.orderableItemId
      : recommendation?.orderableItemId;
    const orderableItemName = selectedOrderableItem?.orderableItemName
      ? selectedOrderableItem.orderableItemName
      : recommendation?.orderableItemName;
    const labItemId = orderableItemId; // Normal order
    const immunizationItemID = orderableItemId; // Immunization order
    const patientID = patientId;
    const recommendationType = recommendation?.recommendationType;
    const assessmentItemId = order?.diagnosis?.assessmentItemId;

    const hasAssessment = !isEmpty(orderAssessments) || !!assessmentItemId;

    function invalidateCache(cache: any) {
      // Invalidate cache
      cache.evict({
        fieldName: 'getEncounterOrders',
      });
    }

    function handleShowErrorToast() {
      if (orderableItemName) {
        toast({
          id: `submission-error-toast-${orderableItemName}`,
          title: 'Error',
          description: `${orderableItemName} failed to submit`,
          status: 'error',
          duration: null,
        });
      }
    }

    function handleShowSuccessToast() {
      toast({
        id: `submission-success-toast`,
        title: 'Success',
        description: `Successfully created ${orderableItemName}`,
        status: 'success',
      });
    }

    // HANDLE ACCEPTED ORDERS
    if (isInjectionOrImmunization) {
      // PROCESS IMMUNIZATION ORDER
      const canAddOrder =
        assignedToUserEmail &&
        documentingFacilityID &&
        encounterID &&
        immunizationItemID &&
        patientID &&
        orderingProviderID &&
        recommendationType;

      if (canAddOrder) {
        return await submitImmunization({
          variables: {
            input: {
              sourceEncounterId: encounterID,
              immunizationItemId: immunizationItemID,
              immunizationName: orderableItemName,
              statusId: 0, // "Pending"
              sourcePatientId: patientID,
              documentingFacilityId: documentingFacilityID,
              patientImmunizationInjectionType: recommendationType,
              lotId: 0,
              orderMetricRecommendationType,
              providerId: orderingProviderID,
              patientImmunizationInjectionAssessments: orderAssessments?.map((a) => {
                return {
                  assessmentValue: a.assessmentValue ?? '',
                  assessmentId: a.assessmentId ?? -1,
                  assessmentName: a.assessmentName ?? '',
                  immunizationID: immunizationItemID,
                };
              }),
            },
          },
          update: invalidateCache,
          onError: () => {
            handleShowErrorToast();
          },
          onCompleted: (res) => {
            if (isMAWorkflowEnabled) {
              const immunizationResponse = res.submitImmunizationInjection;
              dispatch(
                worklistTodoActions.addPendingVaccine({
                  id: orderableItemId,
                  text: orderableItemName ?? '',
                  completed: false,
                  immunization: immunizationResponse,
                }),
              );
            }
            handleShowSuccessToast();
            onCompleted?.();
          },
        });
      }
    } else {
      // PROCESS NON-IMMUNIZATION ORDER
      const canAddOrder =
        hasAssessment &&
        labItemId &&
        patientId &&
        facilityID &&
        encounterID &&
        isNumber(orderableItemTypeId) && // Could be "0"
        orderingProviderID;

      if (canAddOrder) {
        const requestParams: AddOrderableItemMutationVariables = {
          assessmentItemId,
          orderableItemAssessments: orderAssessments,
          labItemId,
          orderableItemName,
          assignedToUserEmail,
          encounterID,
          orderDate: new Date().toISOString(), // TODO: server-side?
          orderableItemTypeId:
            orderableItemTypeId !== null && isNumber(orderableItemTypeId)
              ? orderableItemTypeId
              : -1,
          orderingProviderID,
          patientId,
          facilityID,
          futureOrder,
          fasting,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          orderMetricRecommendationType,
          reason: autoPopulateOrderableItemReason({
            futureOrder,
            recommendationType,
            orderableItemId,
          }),
        };

        return await handleAddOrderableItem({
          variables: requestParams,
          onError: () => {
            handleShowErrorToast();
          },
          update: invalidateCache,
          onCompleted: (res) => {
            if (isMAWorkflowEnabled && isRecommendationLab(order)) {
              const orderableReportID = res.addOrderableItem?.orderableReportID;

              if (orderableReportID && !futureOrder) {
                dispatch(
                  worklistTodoActions.updateLabItem({
                    orderableReportID,
                    orderableItemId,
                    orderableItemName: orderableItemName ?? '',
                  }),
                );
              }
            }
            handleShowSuccessToast();
            onCompleted?.();
          },
        });
      }
    }
  }

  return {
    isLoading: isLoadingSubmitImmunization || isLoadingAddOrderableItem,
    handleAddOrder,
  };
}

export const createManualOrderableItem = (
  orderableItem: OrderableItemLookupUI,
  isFutureOrder: boolean,
  fasting: boolean,
): EditedOrder => {
  const selectedItemId = parseInt(orderableItem?.sourceId || '-1');
  return {
    status: 'YES_ADDING_DIAGNOSIS',
    alert: {
      alertId: selectedItemId,
      shortDescription: orderableItem?.name || '',
      longDescription: '',
      orderableItemTypeId: parseInt(orderableItem?.typeId || '-1'),
      orderableItemId: selectedItemId,
      orderableItemName: orderableItem?.name,
      orderMetricRecommendationType: OrderMetricRecommendationType.Adhoc,
      description: orderableItem?.name,
      recommendationType: convertRecommendationTypeStringToEnum(orderableItem?.type),
      futureOrder: isFutureOrder,
      fasting,
      isOrderable: true,
      displayOrder: 0, // setting it to 0 so it always shows up on top of CDSS page
    },
    orderType: 'ACCEPTED',
  };
};

export const createManualImmunizationOrderableItem = (
  orderableItem: ImmunizationInjectionLookup,
): EditedOrder => {
  const selectedItemId = orderableItem?.immunizationItemId;
  return {
    status: 'YES_ADDING_DIAGNOSIS',
    alert: {
      alertId: selectedItemId,
      shortDescription: orderableItem?.name || '',
      longDescription: '',
      orderableItemId: selectedItemId,
      orderableItemName: orderableItem?.name,
      orderMetricRecommendationType: OrderMetricRecommendationType.Adhoc,
      description: orderableItem?.name,
      recommendationType: convertRecommendationTypeStringToEnum(orderableItem?.type),
      isOrderable: true,
      displayOrder: 0, // setting it to 0 so it always shows up on top of CDSS page
    },
    orderType: 'ACCEPTED',
  };
};

export const convertRecommendationTypeStringToEnum = (
  recommendationType: string | null,
): RecommendationType | null => {
  if (!recommendationType) return null;
  switch (recommendationType) {
    case 'Immunization':
      return RecommendationType.Immunization;
    case 'Injection':
      return RecommendationType.Injection;
    case 'Lab':
      return RecommendationType.Lab;
    case 'Diagnostic Imaging':
      return RecommendationType.Imaging;
    case 'Procedure':
      return RecommendationType.Procedure;
    default:
      return RecommendationType.Lab;
  }
};

export const convertPatientProblemsToSelectData = (patientProblems: Problem[]): OptionItem[] => {
  return patientProblems.map((i) => ({
    id: i.assessmentItemId || -1,
    value: i.problemName || '',
  }));
};

export const convertOrderableItemsToAsyncDropdownOptions = (
  orderableItemsData?: OrderableItemLookupUI[],
): DropdownOption[] => {
  return (
    orderableItemsData?.map((i) => ({
      value: i.sourceId.toString(),
      label: i.name,
    })) || []
  );
};

export const convertInjectionLookupItemToAsyncDropdownOptions = (
  items?: ImmunizationInjectionLookup[],
): DropdownOption[] => {
  return (
    items?.map((i) => ({
      value: i.immunizationItemId.toString(),
      label: i.name,
    })) || []
  );
};

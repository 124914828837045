import { useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import {
  Center,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from '../../../components';
import {
  type EncounterCategoryDataItem,
  type EncounterHPIDataItem,
  type HPICategoryDataItem,
  type HPISymptom,
} from '../../../types';
import { HPIPanelRow } from './HPIPanelRow';
import _ from 'lodash';
import {
  useGetEncounterHpiLazyQuery,
  useGetHpiCategoryDetailsWithSmartFormLazyQuery,
  useGetHpiCategoryDetailsWithSmartFormQuery,
} from '../../../__generated__/graphql';
import { modalActions, uiActions, useAppDispatch } from '../../../state-management';
import { useActiveEncounter, useMAWorkflow, usePatient } from '../../../hooks';
import { Heading } from '@chakra-ui/react';
import { handleCustomSmartForm } from './utils/handleCustomSmartForm';
import { notEmpty } from '../../../utils';
import { symptomSortLogic } from './utils/symptomSortLogic';

interface HPIPanelProps {
  setHpiCategoryDataItem: (hpiCategoryDataItem: HPICategoryDataItem) => void;
}

export function HPIPanel({ setHpiCategoryDataItem }: HPIPanelProps) {
  const dispatch = useAppDispatch();
  const { activeEncounterId } = useActiveEncounter();
  const { patientId } = usePatient();

  const [searchValue, setSearchValue] = useState('');

  const {
    hpi: {
      selectedSymptomId: selectedLeftPanelSymptomId,
      selectedCategoryId,
      selectedEncounterHPICategories,
      selectedEncounterCategory,
    },
  } = useMAWorkflow();

  // this is guaranteed/null-checked by the parent component
  const hpiCategoryDataItem = selectedEncounterCategory!;

  useEffect(() => {
    setSearchValue('');
  }, [selectedCategoryId]);

  const { data: hpiCategoryDetailsData, loading: isLoading } =
    useGetHpiCategoryDetailsWithSmartFormQuery({
      variables: {
        categoryId: hpiCategoryDataItem.hpiCategoryId || -1,
      },
      skip: _.isNil(hpiCategoryDataItem.hpiCategoryId),
    });

  const hpiCategoryDetails = hpiCategoryDetailsData?.getHPICategoryDetailsWithSmartForm;

  const categorySymptomsIdSet = useMemo(
    () =>
      new Set(
        hpiCategoryDataItem.encounterHPIDataItems?.map((symptom) => symptom.hpiSymptomId || -1),
      ),
    [hpiCategoryDataItem],
  );
  const dataItemsMap =
    hpiCategoryDataItem.encounterHPIDataItems?.reduce<Record<string, EncounterCategoryDataItem>>(
      (accum, item) => {
        if (item.hpiSymptomId) {
          accum[item.hpiSymptomId.toString()] = item;
        }
        return accum;
      },
      {},
    ) || {};

  const getHPICategoryDataItem = (symptom: HPISymptom) => {
    const symptomId = (symptom.hpiSymptomID || -1).toString();
    if (!dataItemsMap[symptomId]) {
      dataItemsMap[symptomId] = {
        hpiSymptomId: symptom.hpiSymptomID,
        hpiCategoryId: hpiCategoryDetails?.hpiCategoryID,
        complainsDenies: null,
        duration: null,
        symptomNotes: null,
        // hpiStructuredData:
      };
    }
    return dataItemsMap[symptomId];
  };

  // TODO: Move to a shared location so it can be used in HPIExistingEncounterSymptomsPanel.tsx to prevent out-of-sync bugs between left and mid panels.
  const sortByHpiSymptomId = (_a: HPISymptom, _b: HPISymptom) => {
    const a = getHPICategoryDataItem(_a);
    const b = getHPICategoryDataItem(_b);
    return symptomSortLogic(a, b);
  };

  // Get only sypmtoms currently displayed in the left panel
  const leftPanelSymptoms = hpiCategoryDetails?.hpiSymptoms
    ?.filter((s) => categorySymptomsIdSet.has(s.hpiSymptomID || -2))
    .sort(sortByHpiSymptomId);

  /**
   * We only want to sort on initial load, not while the MA
   * is changing the values.
   */
  const symptoms = useMemo(() => {
    return (
      hpiCategoryDetails?.hpiSymptoms
        ?.filter((symptom) => {
          return symptom.name
            ? symptom.name.toLowerCase().includes(searchValue.toLowerCase())
            : false;
        })
        .sort(sortByHpiSymptomId) || []
    );
  }, [hpiCategoryDetails, searchValue]);

  // Opens navigation modal when a left panel symptom is clicked
  useEffect(() => {
    if (!leftPanelSymptoms) {
      return;
    }

    const symptom = leftPanelSymptoms.find((s) => s.hpiSymptomID === selectedLeftPanelSymptomId);
    if (symptom) {
      const categoryDataItem = getHPICategoryDataItem(symptom);
      const symptomIndexData = getSymptomIndexForLeftPanel(selectedLeftPanelSymptomId);
      handleShowEditDataItemModalForLeftPanel(
        getModalType(symptom),
        categoryDataItem,
        symptom,
        symptomIndexData.hasNext,
        symptomIndexData.hasPrevious,
      );
    }
  }, [selectedLeftPanelSymptomId, leftPanelSymptoms]);

  const getModalType = (symptom: HPISymptom) => {
    return hpiCategoryDetails?.hpiSmartForms?.some((sf) => sf?.itemID === symptom.hpiSymptomID)
      ? 'HPISmartFormModal'
      : 'EncounterHPIDataItemModal';
  };

  const smartForms = handleCustomSmartForm(notEmpty(hpiCategoryDetails?.hpiSmartForms ?? []));

  // Navigate to the next or previous symptom in the entire list
  const getSymptomIndex = (symptomId?: number | null) => {
    const currentIndex: number = symptoms.findIndex((s) => s.hpiSymptomID === symptomId);
    const hasPrevious = currentIndex > 0;
    const hasNext = currentIndex < symptoms.length - 1;

    return {
      index: currentIndex,
      hasNext,
      hasPrevious,
    };
  };

  const getSymptomIndexForLeftPanel = (symptomId?: number | null) => {
    if (!leftPanelSymptoms) {
      return {
        index: -1,
        hasNext: false,
        hasPrevious: false,
      };
    }

    const currentIndex: number = leftPanelSymptoms.findIndex((s) => s.hpiSymptomID === symptomId);
    const hasPrevious = currentIndex > 0;
    const hasNext = currentIndex < leftPanelSymptoms.length - 1;

    return {
      index: currentIndex,
      hasNext,
      hasPrevious,
    };
  };

  const nextSymptom = (next: boolean, symptom: HPISymptom) => {
    const symptomIndexData = getSymptomIndex(symptom.hpiSymptomID);
    let currentIndex = symptomIndexData.index;
    if (currentIndex > -1) {
      if (next && symptomIndexData.hasNext) {
        currentIndex++;
      }
      if (!next && symptomIndexData.hasPrevious) {
        currentIndex--;
      }

      const nextSymptom = symptoms[currentIndex];
      const nextSymptomIndexData = getSymptomIndex(nextSymptom.hpiSymptomID);
      const dataItem = getHPICategoryDataItem(nextSymptom);
      handleShowEditDataItemModal(
        getModalType(nextSymptom),
        dataItem,
        nextSymptom,
        nextSymptomIndexData.hasNext,
        nextSymptomIndexData.hasPrevious,
      );
    }
  };

  const [getHpiCategory] = useGetHpiCategoryDetailsWithSmartFormLazyQuery();
  const [getEncounterHpi] = useGetEncounterHpiLazyQuery();

  // handle navigating to a specific symptom
  // used in the smart form modal to navigate from
  // once specific symptom modal to another upon completion
  // for ex. navigate from phq2 or phq2-2015 to phq9
  const goToSymptom = async ({
    symptomId,
    categoryId,
  }: {
    symptomId: number;
    categoryId: number;
  }) => {
    // we need to get the category data for the next symptom
    // but it may not be currently loaded, so fetch the data
    // since the fetch currently only supports name we pass categoryName
    const [categoryRes, encounterHpiRes] = await Promise.all([
      getHpiCategory({ variables: { categoryId } }),
      getEncounterHpi({ variables: { encounterId: activeEncounterId!, patientId } }),
    ]);

    const category = categoryRes.data?.getHPICategoryDetailsWithSmartForm;
    const encounterCatData = encounterHpiRes.data?.getEncounterHPI?.hpiCategoryData
      ?.filter((catData) => {
        return catData.hpiCategoryId === categoryId;
      })
      ?.map((catData) => catData.encounterHPIDataItems)
      .flat();

    if (!category) return;

    const baseDataItem: HPICategoryDataItem = {
      hpiCategoryId: categoryId,
      name: category.name || null,
      encounterHPIDataItems: [],
      hpiCategoryNotesHeader: '',
    };

    dispatch(uiActions.addHPISelectedEncounterCategory(baseDataItem));

    const symptom = category.hpiSymptoms?.find((s) => s.hpiSymptomID === symptomId);
    const smartForms = handleCustomSmartForm(notEmpty(category.hpiSmartForms ?? []), symptomId);

    if (!symptom) return;

    const symptomData = encounterCatData?.find((catData) => catData?.hpiSymptomId === symptomId);

    const dataItem: EncounterCategoryDataItem = {
      complainsDenies: symptomData?.complainsDenies,
      duration: symptomData?.duration,
      hpiCategoryId: categoryId,
      hpiSymptomId: symptomId,
      hpiStructuredData: symptomData?.hpiStructuredData ?? [],
      symptomNotes: symptomData?.symptomNotes,
    };

    handleShowEditDataItemModal('HPISmartFormModal', dataItem, symptom, false, false, smartForms);
  };

  const nextSymptomForLeftPanel = (next: boolean, symptom: HPISymptom) => {
    const symptomIndexData = getSymptomIndexForLeftPanel(symptom.hpiSymptomID);
    let currentIndex = symptomIndexData.index;
    if (currentIndex > -1) {
      if (next && symptomIndexData.hasNext) {
        currentIndex++;
      }
      if (!next && symptomIndexData.hasPrevious) {
        currentIndex--;
      }

      const nextSymptom = leftPanelSymptoms?.[currentIndex];
      if (!nextSymptom) {
        return;
      }

      const nextSymptomIndexData = getSymptomIndexForLeftPanel(nextSymptom.hpiSymptomID);
      const dataItem = getHPICategoryDataItem(nextSymptom);

      // https://developer.mozilla.org/en-US/docs/Web/API/Window/queueMicrotask
      // basically there's a race condition where we're paginating and resetting the
      // selectedHPI data at the same time. if we paginate, then reset the selectedHPI data, the next symptom
      // is incorrect. this let's us wait for other code to be completed and then runs
      // it's sort of like invoking nextTick in vue or a setTimeout to wait for the state to be updated
      queueMicrotask(() => {
        handleShowEditDataItemModalForLeftPanel(
          getModalType(nextSymptom),
          dataItem,
          nextSymptom,
          nextSymptomIndexData.hasNext,
          nextSymptomIndexData.hasPrevious,
        );
      });
    }
  };

  const handleShowEditDataItemModal = (
    modalType: ModalTypes,
    dataItem: EncounterCategoryDataItem,
    symptom: HPISymptom,
    enableNext: boolean,
    enableBack: boolean,
    overrideSmartForms?: typeof smartForms,
  ) => {
    dispatch(
      modalActions.showModal({
        modalType,
        modalProps: {
          encounterHPIDataItem: dataItem,
          categoryName: hpiCategoryDataItem.name,
          symptom,
          saveSymptom: (
            encounterHPIDataItem: EncounterHPIDataItem,
            symptom: HPISymptom,
            currentCategories: HPICategoryDataItem[],
          ) => {
            if (!_.isEqual(encounterHPIDataItem, dataItem)) {
              setDataItemHandler(encounterHPIDataItem, symptom, currentCategories);
            }
          },
          nextHandler: nextSymptom,
          goToSymptom,
          enableNext,
          enableBack,
          onModalClose() {
            dispatch(uiActions.setHPISelectedSymptomId(undefined));
          },
          smartForms: overrideSmartForms || smartForms,
        },
        chakraModalProps: { size: '4xl' },
      }),
    );
  };

  const handleShowEditDataItemModalForLeftPanel = (
    modalType: ModalTypes,
    dataItem: EncounterCategoryDataItem,
    symptom: HPISymptom,
    enableNext: boolean,
    enableBack: boolean,
  ) => {
    dispatch(
      modalActions.showModal({
        modalType,
        modalProps: {
          encounterHPIDataItem: dataItem,
          categoryName: hpiCategoryDataItem.name,
          symptom,
          saveSymptom: (
            encounterHPIDataItem: EncounterHPIDataItem,
            symptom: HPISymptom,
            currentCategories: HPICategoryDataItem[],
          ) => {
            if (!_.isEqual(encounterHPIDataItem, dataItem)) {
              setDataItemHandler(encounterHPIDataItem, symptom, currentCategories);
            }
          },
          nextHandler: nextSymptomForLeftPanel,
          goToSymptom,
          enableNext,
          enableBack,
          onModalClose() {
            dispatch(uiActions.setHPISelectedSymptomId(undefined));
          },
          smartForms: hpiCategoryDetails?.hpiSmartForms,
        },
        chakraModalProps: { size: '4xl' },
      }),
    );
  };

  const setDataItemHandler = (
    dataItem: EncounterCategoryDataItem,
    symptom: HPISymptom,
    currentCategories: HPICategoryDataItem[],
  ) => {
    // get the category data item from the selected categories list
    const hpiCategoryDataItem = currentCategories?.find(
      (cat) => cat.hpiCategoryId === dataItem.hpiCategoryId,
    );

    if (!hpiCategoryDataItem) {
      return;
    }

    const updatedEncounterHPIDataItem = { ...dataItem };

    dataItemsMap[(symptom.hpiSymptomID || -1).toString()] = updatedEncounterHPIDataItem;
    setHpiCategoryDataItem(
      produce(hpiCategoryDataItem, (draftHpiCategoryDataItem) => {
        if (_.isNil(hpiCategoryDataItem.hpiCategoryId) || _.isNil(symptom.hpiSymptomID)) {
          return;
        }
        const index = draftHpiCategoryDataItem.encounterHPIDataItems?.findIndex(
          (x) => x.hpiSymptomId === updatedEncounterHPIDataItem.hpiSymptomId,
        );

        updatedEncounterHPIDataItem.hpiCategoryId = hpiCategoryDataItem.hpiCategoryId;
        updatedEncounterHPIDataItem.hpiSymptomId = symptom.hpiSymptomID;

        if (draftHpiCategoryDataItem?.encounterHPIDataItems && index !== undefined && index > -1) {
          draftHpiCategoryDataItem.encounterHPIDataItems[index] = updatedEncounterHPIDataItem!;
        } else if (draftHpiCategoryDataItem.encounterHPIDataItems) {
          draftHpiCategoryDataItem.encounterHPIDataItems.push(updatedEncounterHPIDataItem);
        }
      }),
    );
  };

  return (
    <VStack spacing='md' alignItems='flex-start' w='full' py='sm'>
      <Heading fontSize='2xl' mb='sm'>
        {hpiCategoryDataItem.name}
      </Heading>
      <Input
        placeholder={`Find in ${hpiCategoryDataItem.name}`}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <Textarea
        placeholder='Category Notes'
        value={hpiCategoryDataItem.hpiCategoryNotesHeader || ''}
        onChange={(e) => {
          setHpiCategoryDataItem(
            produce(hpiCategoryDataItem, (draftHpiCategoryDataItem) => {
              draftHpiCategoryDataItem.hpiCategoryNotesHeader = e.target.value;
            }),
          );
        }}
      />
      {isLoading ? (
        <Center w='full'>
          <Spinner />
        </Center>
      ) : (
        <TableContainer w='full'>
          <Table w='full' variant='simple'>
            <Thead>
              <Tr>
                <Th textAlign='center'>Complains</Th>
                <Th textAlign='center'>Denies</Th>
                <Th>Symptom</Th>
                <Th>Notes</Th>
                <Th>Duration</Th>
              </Tr>
            </Thead>

            <Tbody>
              {symptoms.map((symptom) => {
                const encounterHPIDataItem = getHPICategoryDataItem(symptom);
                return (
                  <HPIPanelRow
                    smartForm={smartForms}
                    key={symptom.hpiSymptomID}
                    symptom={symptom}
                    editHandler={() => {
                      const symptomIndexData = getSymptomIndex(symptom.hpiSymptomID);
                      handleShowEditDataItemModal(
                        getModalType(symptom),
                        encounterHPIDataItem,
                        symptom,
                        symptomIndexData.hasNext,
                        symptomIndexData.hasPrevious,
                      );
                    }}
                    encounterHPIDataItem={encounterHPIDataItem}
                    setEncounterHPIDataItem={(updatedEncounterHPIDataItem) => {
                      if (updatedEncounterHPIDataItem) {
                        setDataItemHandler(
                          updatedEncounterHPIDataItem,
                          symptom,
                          selectedEncounterHPICategories,
                        );
                      }
                    }}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </VStack>
  );
}

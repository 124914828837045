import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  Page,
  UserInfo,
  InputRightElement,
  WaitUntilLoaded,
  Textarea,
} from '../../components';
import { useEffect, useMemo, useState } from 'react';
import MAWorkFlowHeader from '../MAWorkFlowScreen/MAWorkFlowHeader';
import { BiSearch } from 'react-icons/bi';
import { EncounterTemplateRow } from './EncounterTemplateRow';
import { Button, Drawer, HStack } from '@chakra-ui/react';
import { EncounterTemplatePreview } from './EncounterTemplatePreview';
import {
  type EncounterTemplateMetaData,
  useGetEncounterChiefComplaintsQuery,
  useGetEncounterTemplatesQuery,
  useGetUserFavoriteEncounterTemplatesQuery,
  useUpdateUserEncounterTemplateFavoritesMutation,
  useApplyEncounterTemplatesToEncounterMutation,
  useGetEncounterRosDataQuery,
} from '../../__generated__/graphql';
import {
  useCustomToast,
  useEncounter,
  useMAWorkflow,
  useNavigation,
  usePatient,
} from '../../hooks';
import { useSearchParams } from 'react-router-dom';
import { MAWorkFlowContentContainer } from '../MAWorkFlowScreen/MAWorkFlowContentContainer';
import { type EncounterTemplateMetaDataUI } from '../../types';
import { refetchDataActions } from '../../state-management/refetchDataSlice';
import { useDispatch } from 'react-redux';
import { useChiefComplaints } from '../MAWorkFlowScreen/ChiefComplaintsPanel/useChiefComplaints';
import { uiActions, selectors, useAppSelector } from '../../state-management';
import { useSetDirty } from '../../hooks/useWatchDirtySteps/useSetDirty';
import { localStorageService } from '../../services/localStorageService';

const DIRTY_WORKFLOWS_ON_APPLY: OrderFlowSteps[] = [
  'chief-complaint',
  'ros',
  'hpi',
  'social-history',
  'obgyn',
];

const sortTemplates = (a: EncounterTemplateMetaDataUI, b: EncounterTemplateMetaDataUI) => {
  const aHasAylo = a.name.includes('Aylo');
  const bHasAylo = b.name.includes('Aylo');
  if (aHasAylo) {
    return -1;
  }
  if (bHasAylo) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
const convertSearchStringToArray = (search: string) => {
  return search.split(',');
};

export const EncounterTemplateScreen = () => {
  const [searchParams] = useSearchParams();
  const { activeEncounterID } = useEncounter();
  const { refetch: refetchPatient, refetching: refetchingPatient } = usePatient();
  const [favoriteTemplates, setFavoriteTemplates] = useState<EncounterTemplateMetaDataUI[]>([]);
  const [nonFavoriteTemplates, setNonFavoriteTemplates] = useState<EncounterTemplateMetaDataUI[]>(
    [],
  );
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [previewId, setPreviewId] = useState(-1);
  const [showPreview, setShowPreview] = useState(false);
  const toast = useCustomToast();
  const [templateSearchValue, setTemplateSearchValue] = useState(
    convertSearchStringToArray(searchParams.get('q') || ''),
  );
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { patientId } = usePatient();
  const { getHasLoadedInitialData } = useMAWorkflow();
  const hasLoadedInitialData = getHasLoadedInitialData('chief-complaint');
  const setDirty = useSetDirty();

  const { data: existingComplaintsCacheData, loading: loadingComplaints } =
    useGetEncounterChiefComplaintsQuery({
      variables: { patientId, encounterId: activeEncounterID },
    });
  useEffect(() => {
    if (existingComplaintsCacheData && !hasLoadedInitialData) {
      dispatch(uiActions.setHasLoadedInitialData('chief-complaint'));
      dispatch(
        uiActions.setChiefComplaints(existingComplaintsCacheData.getEncounterChiefComplaints || []),
      );
    }
  }, [existingComplaintsCacheData]);
  const { complaintNames } = useChiefComplaints();
  const { data: encounterTemplatesData, loading: loadingEncounterTemplates } =
    useGetEncounterTemplatesQuery({
      variables: {
        filter: { names: templateSearchValue.map((val) => val.trim()) },
        limit: 100,
        offset: 0,
      },
    });
  const { data: favoritedEncounterTemplateData, loading: loadingFavoritedUserEncounterTemplates } =
    useGetUserFavoriteEncounterTemplatesQuery({
      variables: { filter: {}, limit: 500, offset: 0 },
    });

  const [updateUserTemplateFavorite, { loading: loadingUpdateFavorites }] =
    useUpdateUserEncounterTemplateFavoritesMutation();

  const [applyTemplates, { loading: loadingApplyTemplates }] =
    useApplyEncounterTemplatesToEncounterMutation();

  const { refetch: refetchEncounterRosData } = useGetEncounterRosDataQuery({
    variables: { encounterId: activeEncounterID },
    skip: true, // Skip initial fetch
  });

  const convertToUiTemplates = (
    isFavorite: boolean,
    templates?: EncounterTemplateMetaData[],
  ): EncounterTemplateMetaDataUI[] => {
    return (templates || [])
      .map((template) => {
        return { ...template, isFavorite };
      })
      .sort(sortTemplates);
  };

  const convertAndSetFavorites = (templates?: EncounterTemplateMetaData[]) => {
    setFavoriteTemplates(convertToUiTemplates(true, templates));
  };

  useEffect(() => {
    convertAndSetFavorites(favoritedEncounterTemplateData?.getUserFavoriteEncounterTemplates.items);
  }, [favoritedEncounterTemplateData]);

  useEffect(() => {
    const encounterTemplates = convertToUiTemplates(
      false,
      encounterTemplatesData?.getEncounterTemplates?.items,
    );
    const favoriteIds = favoriteTemplates.reduce<Set<number>>((accum, t) => {
      accum.add(t.templateId);
      return accum;
    }, new Set());
    const nonFavoriteTemplates = encounterTemplates.filter((t) => !favoriteIds.has(t.templateId));
    nonFavoriteTemplates.sort(sortTemplates);
    setNonFavoriteTemplates(nonFavoriteTemplates);
  }, [favoriteTemplates, encounterTemplatesData]);
  const findTemplate = (templateId: number) => {
    return [...nonFavoriteTemplates, ...favoriteTemplates].find((t) => t.templateId === templateId);
  };
  const rowSelectionHandler = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const addToFavoritesHandler = async (selectedId: number): Promise<void> => {
    const favoriteTemplateIds = favoriteTemplates.map((t) => t.templateId);
    const resp = await updateUserTemplateFavorite({
      variables: {
        input: [...favoriteTemplateIds, selectedId],
      },
    });
    convertAndSetFavorites(resp.data?.updateUserEncounterTemplateFavorites);
    toast({
      id: 'encounter-template-favorite',
      title: 'Updated Favorites',
      status: 'success',
      duration: 3000,
    });
  };

  const removeFromFavoritesHandler = async (selectedId: number): Promise<void> => {
    const favoriteTemplateIds = favoriteTemplates
      .map((t) => t.templateId)
      .filter((id) => id !== selectedId);
    const resp = await updateUserTemplateFavorite({
      variables: {
        input: favoriteTemplateIds,
      },
    });
    convertAndSetFavorites(resp.data?.updateUserEncounterTemplateFavorites);
    toast({
      id: 'encounter-template-favorite',
      title: 'Updated Favorites',
      status: 'success',
      duration: 3000,
    });
  };

  const orderingProviderId = useAppSelector(selectors.getActiveProviderID) || -1;

  const applyTemplatesHandler = (templateIds: number[]) => {
    applyTemplates({
      variables: {
        input: {
          encounterId: activeEncounterID,
          templateIds,
          providerId: orderingProviderId,
        },
      },
      update(cache) {
        // Evict everything from the Apollo cache
        cache.evict({ fieldName: undefined });
        cache.gc();
      },
    })
      .then(async (resp) => {
        return refetchPatient().then(() => {
          return resp;
        });
      })
      .then(async () => {
        // Refetch and reset ROS data
        const { data: rosData } = await refetchEncounterRosData();
        if (rosData?.getEncounterROSData) {
          dispatch(
            uiActions.setRosData({
              encounterROSDataItems: rosData.getEncounterROSData.encounterROSDataItems,
              notes: rosData.getEncounterROSData.notes || '',
            }),
          );
          dispatch(uiActions.setAllRosDataItems(rosData.getEncounterROSData.encounterROSDataItems));
          dispatch(uiActions.setROSSelectedCategoryId(-1));
          dispatch(uiActions.setROSSelectedSymptomId(undefined));
        }
      })
      .then((resp) => {
        toast({
          id: 'encounter-template-apply',
          title: 'Template Applied Successfully!',
          status: 'success',
          duration: 3000,
        });
        navigation.toOrderFlowPage(undefined, { replace: true });
        dispatch(refetchDataActions.setEncounterTemplateLastApplyTime(new Date()));
        DIRTY_WORKFLOWS_ON_APPLY.forEach((value) => {
          setDirty(value, true);
        });
        localStorageService.setEncounterLastActivePage(activeEncounterID, 'vitals');
        dispatch(uiActions.onOrderFlowStepChange('vitals'));
      });
  };

  const previewHandler = (templateId: number) => {
    setPreviewId(templateId);
    setShowPreview(true);
  };

  const favoriteTemplatesFiltered = useMemo(() => {
    if (templateSearchValue) {
      const regexes = templateSearchValue.map((value) => new RegExp(value, 'i'));
      return favoriteTemplates.filter((t) => !!regexes.find((regex) => t.name.search(regex) > -1));
    }
    return favoriteTemplates;
  }, [favoriteTemplates, templateSearchValue]);

  const skipHandler = () => {
    navigation.toOrderFlowPage(undefined, { replace: true });
  };

  const selectedTemplate = findTemplate(previewId);
  const isLoading = loadingApplyTemplates || loadingUpdateFavorites || refetchingPatient;
  return (
    <Page>
      <Drawer
        isOpen={showPreview}
        placement='right'
        onClose={() => {
          setShowPreview(false);
        }}
        isFullHeight
        size='full'>
        <EncounterTemplatePreview
          templateMetaData={selectedTemplate}
          onClose={() => {
            setShowPreview(false);
          }}
          onUse={(id) => {
            applyTemplatesHandler([id]);
          }}
          isApplyingTemplate={loadingApplyTemplates || refetchingPatient}
        />
      </Drawer>
      <Flex mb='lg' flexDirection='column'>
        <Box pr='4' mb='md' display='flex' justifyContent='space-between'>
          <UserInfo />
          <Textarea
            width='50%'
            fontSize='lg'
            readOnly={true}
            value={
              'Chief Complaints: ' + (loadingComplaints ? 'Loading...' : complaintNames.join(', '))
            }
          />
        </Box>
        <MAWorkFlowContentContainer>
          <Flex flexDirection='column' minHeight={500}>
            <MAWorkFlowHeader mb='md'>Select A Template</MAWorkFlowHeader>
            <Flex w='100%' flexDirection='column'>
              <InputGroup size='md' mb='md'>
                <Input
                  pr='4.5rem'
                  placeholder='Search Templates'
                  value={templateSearchValue}
                  onChange={(e) => {
                    setTemplateSearchValue(convertSearchStringToArray(e.target.value));
                  }}
                />
                <InputRightElement>
                  <BiSearch />
                </InputRightElement>
              </InputGroup>
              <Flex flexDirection='row' width='100%'>
                <Flex flexDirection='column' width='100%' mr='sm'>
                  <Text mb='md'>Favorites</Text>
                  <WaitUntilLoaded loading={loadingFavoritedUserEncounterTemplates}>
                    {favoriteTemplatesFiltered.length ? (
                      favoriteTemplatesFiltered.map((entry) => (
                        <EncounterTemplateRow
                          key={entry.templateId}
                          isSelected={selectedIds.includes(entry.templateId)}
                          isDisabled={isLoading}
                          template={entry}
                          favoriteClickHandler={removeFromFavoritesHandler}
                          rowClickHandler={rowSelectionHandler}
                          previewClickHandler={previewHandler}
                        />
                      ))
                    ) : (
                      <Text textAlign='center'>None</Text>
                    )}
                  </WaitUntilLoaded>
                </Flex>
                <Flex flexDirection='column' width='100%' ml='sm'>
                  <Text mb='md'>Available</Text>
                  <WaitUntilLoaded loading={loadingEncounterTemplates}>
                    <Box height='80%'>
                      {nonFavoriteTemplates.length ? (
                        nonFavoriteTemplates.map((entry) => (
                          <EncounterTemplateRow
                            key={entry.templateId}
                            isSelected={selectedIds.includes(entry.templateId)}
                            isDisabled={isLoading}
                            template={entry}
                            favoriteClickHandler={addToFavoritesHandler}
                            rowClickHandler={rowSelectionHandler}
                            previewClickHandler={previewHandler}
                          />
                        ))
                      ) : (
                        <Text textAlign='center'>None</Text>
                      )}
                    </Box>
                  </WaitUntilLoaded>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </MAWorkFlowContentContainer>
      </Flex>
      <HStack
        position='fixed'
        bottom='8px'
        w='90%'
        left='5%'
        height='40px'
        backgroundColor='white'
        borderRadius='8px'>
        <Button w='full' size='lg' backgroundColor='secondary' onClick={skipHandler}>
          Cancel
        </Button>
        <Button
          w='full'
          size='lg'
          backgroundColor='primary'
          isDisabled={!selectedIds.length}
          isLoading={loadingApplyTemplates || refetchingPatient || isLoading}
          onClick={() => {
            applyTemplatesHandler(selectedIds);
          }}>
          Use Templates {!selectedIds.length ? '' : `(${selectedIds.length})`}
        </Button>
      </HStack>
    </Page>
  );
};

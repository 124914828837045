import { useEffect, useState } from 'react';
import {
  type HpiCategoryLookup,
  useGetHpiCategoriesQuery,
} from '../../../../__generated__/graphql';
import {
  Flex,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  WaitUntilLoaded,
} from '../../../../components';
import { DEBOUNCE_MILLISECONDS } from '../../../../constants';
import { useDebounce } from '../../../../hooks';
import { uiActions, useAppDispatch } from '../../../../state-management';
import { type HPICategoryDataItem } from '../../../../types';
import { HPIExistingEncounterSymptomsPanel } from '../HPIExistingEncounterSymptomsPanel';
import { HPICategory } from './HPICategory';

interface HPICategoriesProps {
  categoryData?: HPICategoryDataItem[] | null;
}

const TAB_NAMES = ['All', 'Selected'] as const;
type TabName = (typeof TAB_NAMES)[number];

export function HPICategories({ categoryData }: HPICategoriesProps) {
  const selectedCount = categoryData?.length || 0;
  const [activeTab, setActiveTab] = useState<TabName>('All');
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);
  const dispatch = useAppDispatch();
  const { data: hpiCategoriesData, loading: isLoading } = useGetHpiCategoriesQuery({
    variables: { contains: debouncedSearchText, depth: 1 }, // depth to get the level of subCategories
  });
  const hpiCategories = (hpiCategoriesData?.getHPICategories || []) as HpiCategoryLookup[];

  useEffect(() => {
    if (selectedCount > 0) {
      setActiveTab('Selected');
    }
  }, [selectedCount]);

  const clickHandler = (encounterCategory: HPICategoryDataItem, symptomId?: number) => {
    dispatch(uiActions.setHPISelectedCategoryId(encounterCategory.hpiCategoryId || -1));
    dispatch(uiActions.setHPISelectedSymptomId(symptomId || -1));
    dispatch(uiActions.setHPISelectedEncounterCategory(encounterCategory));
    dispatch(uiActions.addHPISelectedEncounterCategory(encounterCategory));
  };

  const allTab = () => {
    return (
      <VStack overflow='auto' h='full'>
        <Flex w='full'>
          <Input
            size='lg'
            borderColor='secondary'
            borderWidth='2px'
            autoFocus
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            mb='sm'
            placeholder='Search'
            _focus={{
              boxShadow: 'primary',
              borderColor: 'brand.500',
            }}
          />
        </Flex>

        <VStack
          w='full'
          align='flex-start'
          h='full'
          justify='flex-start'
          overflow='auto'
          overflowX='hidden'>
          <WaitUntilLoaded loading={isLoading}>
            {hpiCategories?.map((hpiCategory) => {
              const selectedCategoryIds =
                categoryData?.map((category) => category.hpiCategoryId!) ?? [];

              return (
                <HPICategory
                  key={hpiCategory?.hpiCategoryID}
                  category={hpiCategory}
                  onClick={(category) => {
                    clickHandler(category);
                    setSearchText('');
                  }}
                  selectedCategoryIds={selectedCategoryIds}
                  searchText={searchText}
                />
              );
            })}
          </WaitUntilLoaded>
        </VStack>
      </VStack>
    );
  };

  return (
    <VStack spacing='md' w='full' h='full' overflow='auto' pr='md'>
      <Tabs
        index={TAB_NAMES.indexOf(activeTab)}
        onChange={(index) => {
          setActiveTab(TAB_NAMES[index]);
        }}
        colorScheme='brand'
        display='flex'
        flexDirection='column'
        overflow='auto'
        isFitted
        width='100%'>
        <TabList>
          <Tab>All</Tab>
          <Tab>Selected ({selectedCount})</Tab>
        </TabList>

        <TabPanels overflow='auto'>
          <TabPanel px={0} overflow='auto' h='full'>
            {allTab()}
          </TabPanel>
          <TabPanel px={0} overflow='auto' h='full'>
            <HPIExistingEncounterSymptomsPanel
              dataItems={categoryData}
              clickHandler={clickHandler}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

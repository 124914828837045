import { ChevronRightIcon } from '@chakra-ui/icons';
import { MdExpandLess as ExpandIcon } from 'react-icons/md';
import { type HpiCategoryLookup } from '../../../../__generated__/graphql';
import { Box, HStack, Icon, IconButton, Text, VStack } from '../../../../components';
import { useState } from 'react';

interface HPICategoryProps {
  category: HpiCategoryLookup;
  selectedCategoryIds: number[];
  onClick: (categoryData: any) => void;
  searchText: string | undefined;
}

export function HPICategory(props: HPICategoryProps) {
  const [expanded, setExpanded] = useState(false);

  const symtoms = props.category?.hpiSymptoms || [];
  const subCategories = (props.category?.subCategories || []) as HpiCategoryLookup[];
  const isDisabled = props.selectedCategoryIds.includes(props.category.hpiCategoryID!);

  const handleClick = () => {
    if (subCategories.length > 0) {
      setExpanded(!expanded);
    } else {
      if (props.category && !isDisabled) {
        const newCategoryData = {
          hpiCategoryId: props.category.hpiCategoryID || null,
          name: props.category.name || null,
          encounterHPIDataItems: [],
          hpiCategoryNotesHeader: '',
        };
        props.onClick(newCategoryData);
      }
    }
  };

  const renderExpandIcon = () => {
    return (
      <IconButton size={'xs'} aria-label={'Expand'} variant='ghost'>
        <Icon
          boxSize={6}
          as={ExpandIcon}
          transform={expanded ? 'rotate(180deg)' : 'rotate(0deg)'}
          transition='transform 0.2s ease'
        />
      </IconButton>
    );
  };

  const renderSymptoms = () => {
    return (
      <Box w='full' color='secondary' _groupHover={{ color: !isDisabled ? 'primary' : '' }}>
        <Text fontSize='sm' as='sub' pr={1} w='full' textAlign='left'>
          Symptoms <ChevronRightIcon />
        </Text>
        <Text fontSize='sm' as='sub' w='full' textAlign='left'>
          {symtoms?.map((symptom) => symptom?.name).join(', ')}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Box pl='sm' w='full'>
        <Box opacity={isDisabled ? 0.5 : 1} onClick={handleClick} textAlign='left'>
          <Box
            role='group'
            _hover={{ color: !isDisabled ? 'primary' : '' }}
            cursor={isDisabled ? 'default' : 'pointer'}
            mb={3}>
            <VStack spacing={0}>
              <HStack spacing='sm' justifyContent='flex-start' w='full'>
                <Text as='b' whiteSpace='nowrap' w='full' textAlign='left' noOfLines={1} flex={1}>
                  {props.category?.name}
                </Text>
                {subCategories.length > 0 && renderExpandIcon()}
              </HStack>
              {symtoms.length > 0 && props.searchText && renderSymptoms()}
            </VStack>
          </Box>
        </Box>
      </Box>

      {expanded && (
        <VStack spacing={1} pl={'md'}>
          {subCategories.map((subCategory) => (
            <HPICategory
              key={subCategory.hpiCategoryID}
              category={subCategory}
              onClick={props.onClick}
              selectedCategoryIds={props.selectedCategoryIds}
              searchText={props.searchText}
            />
          ))}
        </VStack>
      )}
    </>
  );
}

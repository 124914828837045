import { NetworkStatus } from '@apollo/client';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  ErrorMessage,
  FlatList,
  OrderSummaryCard,
  Text,
  WaitUntilLoaded,
} from '../../../../components';
import { useEncounter, useMAWorkflow, useNavigation, useSidebar } from '../../../../hooks';
import {
  appointmentsActions,
  getOrderFlow,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../../state-management';
import { type Appointment, type EncounterCardInfo, type Patient } from '../../../../types';
import { calculateAge, formatDate, formatTime, formatUTCDate } from '../../../../utils';
import { FullStory as FS } from '@fullstory/browser';
import { useEncounters } from '../../../../hooks/useEncounters';

interface RenderItemProps {
  item: Appointment;
  index: number;
}

// UTILS
/**
 * @param patient
 * @returns "Carolyn Henry 488057"
 */
function getPatientFullNameAndID(patient: Patient) {
  return `${patient?.firstName} ${patient?.lastName} ${patient?.patientID}`;
}

/**
 * @param patient
 * @returns "41 F 01/01/1980"
 */
function getAgeGenderBirthday(patient: Patient) {
  return `${patient?.dateOfBirth && calculateAge(patient.dateOfBirth)} ${patient?.gender} ${
    patient?.dateOfBirth && formatUTCDate(patient.dateOfBirth)
  }`;
}

/**
 * @param encounter
 * @returns "9:30AM - 10:00AM"
 */
function getVisitTimeFromEncounter(encounter: Appointment) {
  if (encounter?.startTime) {
    return `${formatTime(encounter.startTime)} - ${formatTime(encounter.endTime)}`;
  }

  return '';
}

function getDateFromEncounter(encounter: Appointment) {
  return formatDate(encounter?.startTime) ?? '';
}

function getVisitReasonFromEncounter(encounter: Appointment) {
  return encounter?.visitReason;
}
function getVisitStatusFromEncounter(encounter: Appointment) {
  return encounter?.visitStatus;
}
function getInsuranceFromPatient(patient: Patient) {
  return patient?.insurance || 'No Insurance';
}
function getResourceName(encounter: Appointment) {
  return encounter?.resource?.name;
}

function getEncounterCardInfoFromEncounter(encounter: Appointment): EncounterCardInfo {
  const patient = encounter?.patient;
  return {
    encounterID: encounter?.encounterID,
    fullNameAndID: getPatientFullNameAndID(patient),
    genderAgeBirthday: getAgeGenderBirthday(patient),
    time: getVisitTimeFromEncounter(encounter),
    date: getDateFromEncounter(encounter),
    visitType: encounter?.visitType,
    visitReason: getVisitReasonFromEncounter(encounter),
    visitStatus: getVisitStatusFromEncounter(encounter),
    clinicalStatusAfterCheckIn: encounter?.clinicalStatusAfterCheckIn,
    insurance: getInsuranceFromPatient(patient),
    resourceName: getResourceName(encounter) || '',
    roomNumber: encounter.roomNumber,
    isNotStarted: !encounter.clinicalTimeIn,
    isStartedAndNotComplete: !!encounter.clinicalTimeIn && !encounter.clinicalTimeOut,
    isComplete: !!encounter.clinicalTimeIn && !!encounter.clinicalTimeOut,
  };
}

export function EncounterCardList() {
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectors.getPatientSearchScreenActiveTab);
  const isCompleted = activeTab === 'Done';
  const {
    data,
    isFetching,
    error,
    onFetchNextPage,
    updateEncounter,
    isUpdatingEncounter,
    isInitialLoading,
    networkStatus,
  } = useEncounters({ includeLabs: true });

  const nextToken = data?.nextToken;
  const showFetchMoreButton = !!nextToken;

  const { activeEncounterID } = useEncounter();
  const orderFlowCurrentStep = useAppSelector(getOrderFlow).currentStep;
  const { sidebarState, closeSidebar } = useSidebar();

  useEffect(() => {
    if (sidebarState.open) closeSidebar();
  }, [activeEncounterID, orderFlowCurrentStep]);

  const encounters = data?.encounters;

  /**
   * Note: Leaving this error logic here for now since it needs a custom
   * margin top. For now WaitUntilLoaded is just showing the default
   * ErrorMessage.
   */
  if (error != null) {
    return (
      <Box mt='md'>
        <ErrorMessage text='Something went wrong' />
      </Box>
    );
  }

  function renderItem({ item }: RenderItemProps) {
    // TOOD: Eventually use props to populate
    return (
      // TODO: How is list implemented in Review/Confirm Order page?
      // Look into padding of cards on page. Seem to be clipped on edges in Home Page
      <Box mt={4}>
        <OrderSummaryCard
          borderRadius='lg'
          loading={networkStatus === NetworkStatus.loading || isUpdatingEncounter}
          data={getEncounterCardInfoFromEncounter(item)}
          onClick={
            isCompleted && !_.isNil(item.progressNoteLockedDateTime)
              ? () => {
                  dispatch(appointmentsActions.addActiveEncounter(item));
                  navigation.toPastEncounterDetailPage(item.encounterID);
                }
              : () => {
                  // Populate Redux store with active encounter so other pages can use
                  if (isUpdatingEncounter) return;
                  dispatch(appointmentsActions.addActiveEncounter(item));
                  FS('trackEvent', {
                    name: 'View Encounter Clicked',
                    properties: { encounter_id: item.encounterID },
                  });
                  // update the encounter w/ the current start time if the encounter
                  // hasn't been started before
                  if (!item.clinicalTimeIn && item.visitStatus === 'ARR') {
                    const vals = {
                      clinicalTimeIn: new Date().toISOString(),
                      clinicalStatusAfterCheckIn: 'NUR',
                    };
                    dispatch(
                      appointmentsActions.addActiveEncounter({
                        ...item,
                        ...vals,
                      }),
                    );
                    updateEncounter(item.encounterID, vals);
                  }
                  if (isMAWorkflowEnabled) {
                    navigation.toOrderFlowPage();
                  } else {
                    navigation.toReviewOrderPage();
                  }
                }
          }
          onViewOrder={
            isCompleted
              ? () => {
                  // Populate Redux store with active encounter so other pages can use
                  // TODO: Check other pages to figure out what they can fetch independently.
                  dispatch(appointmentsActions.addActiveEncounter(item));
                  navigation.toPastOrderDetailPage();
                }
              : undefined
          }
          updateClinicalStatus={async (encounterId, clinicalStatusAfterCheckIn) => {
            await updateEncounter(encounterId, { clinicalStatusAfterCheckIn });
          }}
          updateRoomNumber={async (encounterId, roomNumber) => {
            await updateEncounter(encounterId, { roomNumber });
          }}
        />
      </Box>
    );
  }
  return (
    <WaitUntilLoaded loading={isInitialLoading && !encounters?.length}>
      {encounters?.length ? (
        <>
          <Box mb='4'>
            <FlatList
              data={encounters}
              renderItem={renderItem}
              keyExtractor={(item: Appointment) => item.encounterID.toString()}
            />
          </Box>
          {showFetchMoreButton && (
            <Button
              onClick={() => {
                onFetchNextPage(nextToken);
              }}
              isLoading={isFetching}
              w='full'>
              {isFetching ? 'Fetching...' : 'See more'}
            </Button>
          )}
        </>
      ) : (
        <Box mt='md'>
          <Center>
            <Text>There are no encounters.</Text>
          </Center>
        </Box>
      )}
    </WaitUntilLoaded>
  );
}

import { HStack, Text, Button, Icon, Tooltip } from '@chakra-ui/react';
import { IoIosRefresh as RefreshIcon } from 'react-icons/io';
import { formatDateWithTime } from '../../../../utils';
import { useGrits } from './useGrits';
import { type Patient, useRefreshGritsMutation } from '../../../../__generated__/graphql';
import { useCustomToast, usePatient } from '../../../../hooks';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';

const LOCAL_STORAGE_KEY = 'gritsLastRetrievedInitiated';

type LastRetrievedLocalStorageConfig = Record<Patient['patientID'], { lastRetrieved: string }>;

/**
 *
 * The LastRetrievedAndRetrieveButton displays the last retrieved time of data and a button to trigger data retrieval.
 * The button is disabled for 2 minutes after it is clicked to prevent multiple rapid requests. The component
 * uses local storage to keep track of the last retrieval time and ensures the button remains
 * disabled until the 2-minute interval has passed. Additionally, it invalidates the Apollo cache
 * for the getGrits field 2 minutes after the retrieval to ensure fresh data is fetched on the next request.
 */
export function LastRetrievedAndRetrieveButton() {
  const { patientId } = usePatient();
  const { data: gritsData } = useGrits();
  const updated = gritsData?.getGrits?.updated;
  const [refreshGritsMutation, { loading, client }] = useRefreshGritsMutation();
  const toast = useCustomToast();
  const [lastRetrievedLocalStorageConfig, setLastRetrievedLocalStorageConfig] =
    useLocalStorage<LastRetrievedLocalStorageConfig | null>(LOCAL_STORAGE_KEY, null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (lastRetrievedLocalStorageConfig) {
      const lastRetrieved = lastRetrievedLocalStorageConfig?.[patientId]?.lastRetrieved;
      const lastRetrievedTime = new Date(lastRetrieved).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - lastRetrievedTime;
      const twoMinutes = 2 * 60 * 1000;
      const isWithinTwoMinutes = timeDifference < twoMinutes;

      if (isWithinTwoMinutes) {
        setIsDisabled(true);
        const timeout = setTimeout(() => {
          client.cache.evict({ fieldName: 'getGrits' });
          client.cache.gc();
          setIsDisabled(false);
        }, twoMinutes - timeDifference);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [lastRetrievedLocalStorageConfig]);

  const handleRetrieveClick = () => {
    const currentTime = new Date().toISOString();
    setLastRetrievedLocalStorageConfig((prev) => ({
      ...prev,
      [patientId]: { lastRetrieved: currentTime },
    }));
    refreshGritsMutation({
      variables: {
        patientId,
      },
      onCompleted: () => {
        toast({
          id: 'grits-refreshed',
          title: 'Public health retrieval in progress',
          description: 'This could take up to 2 mins',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <HStack>
      {updated && (
        <Text fontSize='sm' color='brand.500'>
          Last Retrieved: {updated ? formatDateWithTime(updated) : ''}
        </Text>
      )}
      <Tooltip label='Please wait 2 minutes before retrieving again' isDisabled={!isDisabled}>
        <Button
          isDisabled={isDisabled || loading}
          isLoading={loading}
          onClick={handleRetrieveClick}
          rightIcon={<Icon boxSize={5} as={RefreshIcon} />}>
          Retrieve
        </Button>
      </Tooltip>
    </HStack>
  );
}

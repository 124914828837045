import { type HpiStructuredData } from '../../../../../__generated__/graphql';
import { HpiCalculator } from './base';

export class Phq9ScoreCalc extends HpiCalculator {
  computedField = 2895;

  formula = (responses: HpiStructuredData[]) => {
    const hasEmpty = responses.some((a) => !a.value);

    if (hasEmpty) return;

    const answersAndPrompts = responses.map((response) => {
      const prompt = this.smartForms.find(
        (f) => f.structureDataDetailID === response.hpiStructuredDataDetailId,
      );
      return {
        ...prompt,
        ...response,
      };
    });

    const score = answersAndPrompts.reduce((acc, a) => {
      const idxOfSelection = a.smartFormSurveyStructureOptions?.find(
        (o) => `${o.name}` === a.value,
      )?.displayIndex;

      // handle the index offset
      // displayIndex is base 1 but we want to score base 0
      let score = idxOfSelection ?? 1;
      score -= 1;

      return acc + score;
    }, 0);

    this.score = score;
    return score;
  };
}

import {
  ModalBody,
  Text,
  ModalCloseButton,
  ModalFooter,
  Button,
  Box,
  VStack,
  SimpleGrid,
  HStack,
  Input,
  FormControl as ChakraFormControl,
  FormLabel,
  type FormControlProps,
  Tooltip,
} from '@chakra-ui/react';
import { selectors, useAppSelector } from '../../../state-management';
import { useAuth } from '../../../hooks';
import { ComboBox, DatePicker } from '../../form';
import { ActualFastingSelect } from './ActualFastingSelect';
import { useLabCollectionForm } from './useLabCollectionForm';
import { useEffect } from 'react';
import { FacilitySelect } from '../../../features';
import { CollectionSourceSelect } from './CollectionSourceSelect';
import { CollectionUnitSelect } from './CollectionUnitSelect';
import { ClinicalInfoInput } from './ClinicalInfoInput';
import { InternalNotesInput } from './InternalNotesInput';
import { LabTable } from './LabTable';
import { useWorklistModal } from '../WorklistModal/useWorklistModal';
import { useAOEQuestionsAndLabInstructions } from './useAOEQuestionsAndLabInstructions';

function FormControl({ children }: FormControlProps) {
  return (
    <ChakraFormControl
    // variant='floating'
    >
      {children}
    </ChakraFormControl>
  );
}

export function LabCollectionFormModal() {
  const { hasAOEQuestionsOrLabInstructions, apiState: fetchAOEState } =
    useAOEQuestionsAndLabInstructions();

  const { user } = useAuth();
  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const defaultGivenBySelection = {
    label: fullName,
    value: user.ecwId ? user.ecwId : '',
    firstName: user.firstName,
    lastName: user.lastName,
  };
  const defaultLabCompanySelection = {
    label: 'Orchard',
    value: '13',
  };
  const selectedLocationID = useAppSelector(selectors.getSelectedLocationID); // TODO: Why does this return string when locationID type is number?

  const { toAOEQuestionsAndLabForms, toWorkListModal } = useWorklistModal();
  const {
    handleApply,
    isGlobalFormValid,
    handleSubmit,
    globalLabForm,
    handleUpdateGlobalLabForm,
    labDate,
    hasAtLeastOneSubmittedLabOrder,
    hasAtLeastOneValidLabSubmissionInput,
  } = useLabCollectionForm();

  // Initialize form values
  useEffect(() => {
    handleUpdateLabDate(new Date());
  }, []);

  // Init: Set "Reviewed by"
  useEffect(() => {
    const reviewedByUserId = Number(defaultGivenBySelection.value);
    handleUpdateGlobalLabForm({
      labReviewedByUserId: reviewedByUserId,
      labReviewedByFirstName: defaultGivenBySelection.firstName,
      labReviewedByLastName: defaultGivenBySelection.lastName,
    });
  }, [defaultGivenBySelection.label, defaultGivenBySelection.value]);

  // Init: Set "Send To"
  useEffect(() => {
    handleUpdateSendToLab(defaultLabCompanySelection);
  }, [defaultLabCompanySelection.label, defaultLabCompanySelection.value]);

  // Init: Ordering facility
  useEffect(() => {
    handleUpdateGlobalLabForm({
      orderingFacilityId: selectedLocationID,
    });
  }, [selectedLocationID]);

  // Init: Fasting
  useEffect(() => {
    handleUpdateGlobalLabForm({
      actualFasting: 2, // Not Recorded
    });
  }, []);

  function handleUpdateLabDate(date: Date) {
    handleUpdateGlobalLabForm({ collectionDateTime: date.toISOString() });
  }

  function handleUpdateSendToLab(selection?: { label: string; value: string }) {
    if (selection) {
      handleUpdateGlobalLabForm({
        sendToLabCompanyId: Number(selection?.value),
        sendTolabCompanyName: selection?.label,
      });
    }
  }

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box h='70vh'>
          <Text variant='h6-b' mb='md'>
            Send labs
          </Text>
          {hasAtLeastOneSubmittedLabOrder ? (
            <VStack align={'stretch'} spacing='lg'>
              <LabTable />

              <VStack spacing='sm' align={'stretch'}>
                <Text fontWeight={'bold'}>Collection Data</Text>
                <SimpleGrid columns={2} spacing={'sm'}>
                  <FormControl>
                    <FormLabel>Send To</FormLabel>
                    <ComboBox
                      isLoading={false}
                      placeholder='Send To'
                      onSelection={(selection) => {
                        handleUpdateSendToLab(selection);
                      }}
                      required={true}
                      options={[defaultLabCompanySelection]}
                      defaultValue={defaultLabCompanySelection}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Order Facility</FormLabel>
                    <FacilitySelect
                      placeholder='Order Facility'
                      onSelection={(selection) => {
                        handleUpdateGlobalLabForm({
                          orderingFacilityId: Number(selection?.value),
                        });
                      }}
                      defaultValueId={selectedLocationID}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Collection Date / Time</FormLabel>
                    <DatePicker
                      placeholder='Collection Date / Time'
                      value={labDate ? new Date(labDate) : null}
                      onChange={(date) => {
                        if (date) {
                          handleUpdateLabDate(date);
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Actual Fasting</FormLabel>
                    <ActualFastingSelect />
                  </FormControl>
                </SimpleGrid>

                <VStack align={'stretch'}>
                  <HStack>
                    <FormControl>
                      <FormLabel>Collection Source</FormLabel>
                      <CollectionSourceSelect />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Collection Volume</FormLabel>
                      <Input
                        placeholder='Collection Volume'
                        value={globalLabForm?.collectionVolume ?? undefined}
                        onChange={(e) => {
                          handleUpdateGlobalLabForm({
                            collectionVolume: e.target.value,
                          });
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Unit</FormLabel>
                      <CollectionUnitSelect />
                    </FormControl>
                  </HStack>

                  <HStack>
                    <FormControl>
                      <FormLabel>Clinical Info</FormLabel>
                      <ClinicalInfoInput />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Comments</FormLabel>
                      <InternalNotesInput />
                    </FormControl>
                  </HStack>
                </VStack>
                <Tooltip
                  isDisabled={hasAtLeastOneValidLabSubmissionInput || isGlobalFormValid}
                  label='Please enter required form data first'
                  placement='top'>
                  <Button isDisabled={!isGlobalFormValid} onClick={handleApply}>
                    Apply To Selected Labs
                  </Button>
                </Tooltip>
              </VStack>
            </VStack>
          ) : (
            <Text>No data</Text>
          )}
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={toWorkListModal}>
          Back
        </Button>
        <Tooltip
          isDisabled={hasAtLeastOneValidLabSubmissionInput}
          label='Apply data to at least one lab'
          placement='top'>
          <Button
            isDisabled={!hasAtLeastOneValidLabSubmissionInput}
            isLoading={fetchAOEState.isLoading}
            onClick={hasAOEQuestionsOrLabInstructions ? toAOEQuestionsAndLabForms : handleSubmit}>
            {hasAOEQuestionsOrLabInstructions ? 'AOE and Instructions' : 'Send'}
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
}
